import React, { useEffect } from "react";

import '../../i18n';
import { useTranslation } from 'react-i18next';

const Tutsagiuseppe = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const { t } = useTranslation();

  return <h1 align="center">......{t('msg001.label')}......</h1>;
};

export default Tutsagiuseppe;
