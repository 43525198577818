import React, { useEffect } from "react";

import PdfDownloadLogo from "../../images/pdf-download-logo.png";

import Foto01 from "../../photos/scuole/infanziaIsola-00.jpg";
import Foto02 from "../../photos/sanvincenzodepaoli/eIsola-02.jpg";

import Foto03 from "../../photos/scuole/infanziaIsola-01.jpg";
import Foto04 from "../../photos/scuole/infanziaIsola-02.jpg";
import Foto05 from "../../photos/scuole/infanziaIsola-03.jpg";
import Foto06 from "../../photos/scuole/infanziaIsola-04.jpg";
import Foto07 from "../../photos/scuole/infanziaIsola-05.jpg";

import GalleriaLogo from "../../images/galleria.jpg";

import LogoFacebook from "../../images/facebook.png";

import "../../css/home/home.css";
//import NameForm from "./nameform";
import ResponsiveImage from "./ResponsiveImage";
import { Link } from "react-router-dom";

import '../../i18n';
import { useTranslation } from 'react-i18next';

const iframe =
  '<iframe height="500" style="width: 100%;" scrolling="no" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=via%20selva%2045%20isola%20del%20liri+(Titolo)&amp;ie=UTF8&amp;t=k&amp;z=17&amp;iwloc=B&amp;output=embed" frameborder="no" marginheight="0" marginwidth="0"</iframe>';

function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
    />
  );
}

const InfanziaIsola = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const { t } = useTranslation();

  return (
    <div className="home-centro-h">
      <div className="home-content">
        <div className="row">
          <div className="col-sm-8" align="center">
            <ResponsiveImage src={Foto01} width={900} height={600} />
          </div>
          <div className="col-sm-4 home-siamodasempre">
            <hr width="150" size="1" color="#20b2aa" align="center" />

            <h1>{t('scuolainfanziaisola001.label')}</h1>
            <h5>via Selva 45 - 03036 Isola del Liri (FR)</h5>

            <div className="row">
              <div className="col-1" />
              <div className="col-5">
                <h5>Tel. 0776 808119</h5>
              </div>
              <div className="col-2">
                <a
                  href="https://www.facebook.com/poverefiglie.dellavisitazione/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="facebook"
                    src={LogoFacebook}
                    className="logo-facebook"
                    width="30"
                    height="30"
                  />
                </a>
              </div>
              <div className="col-2">
                <Link className="link" to="/scuola">
                  <img
                    alt=""
                    src={GalleriaLogo}
                    width="30"
                    height="30"
                    title={t('msg026.label')}
                  />
                </Link>
              </div>
            </div>
            <h5>Email isola@istitutosuorevisitazione.it</h5>
            <a
              href="http://www.scuoledellavisitazione.it/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('msg038.label')}
            </a>
            <h2>{t('scuolainfanziaisola002.label')}</h2>

            <p></p>
            <hr width="150" size="1" color="#20b2aa" align="center" />
            <p></p>
            <div className="row">
              <div className="col" />

              <div className="col">
                <img
                  height="30"
                  width="30"
                  className="book-passionate"
                  src={PdfDownloadLogo}
                  alt="team"
                />
              </div>
              <div className="col-auto">
                <h4>{t('msg013.label')}</h4>
              </div>
              <div className="col" />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-2" />

          <div className="col-lg-8">
            <h1 className="home-top20-c">
            {t('scuolainfanziaisola003.label')}
            </h1>
            <h5 className="home-top20-j">
            {t('scuolainfanziaisola004.label')}
            </h5>
            <h1 className="home-top20-c">{t('msg003.label')}</h1>

            <Iframe iframe={iframe} />
          </div>
          <div className="col-lg-2" />
        </div>

        <div className="row home-top20-verde">
          <div className="col-sm-1" />

          <div className="col-sm-10">
            <font color="white">
              <h1>{t('scuolainfanziaisola005.label')}</h1>
              <p></p>
            </font>
            <div className="row" align="center">
              <p></p>
              <div className="col">
                <ResponsiveImage src={Foto01} width={550} height={350} />
              </div>
              <div className="col">
                <font color="white" align="justify">
                  <h5>
                  {t('scuolainfanziaisola006.label')}
                  </h5>
                </font>
              </div>
            </div>
            <p></p>
            <div className="row" align="center">
              <p></p>
              <div className="col">
                <ResponsiveImage src={Foto02} width={550} height={400} />
                <p></p>
                <ResponsiveImage src={Foto06} width={550} height={400} />
                <p></p>
                <ResponsiveImage src={Foto03} width={550} height={700} />
              </div>
              <div className="col">
                <ResponsiveImage src={Foto04} width={550} height={400} />
                <p></p>
                <ResponsiveImage src={Foto05} width={550} height={400} />
                <p></p>
                <ResponsiveImage src={Foto07} width={550} height={700} />
              </div>
              
                
              <p></p>
                
            
            </div>
            <p></p>
          </div>

          <div className="col-sm-1" />
          <p></p>
        </div>
      </div>
    </div>
  );
};

export default InfanziaIsola;
