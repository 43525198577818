import axios from "axios";

export default class LoginService {
  login(wsMode, usrUsername, usrPassword, idDevice, onSuccess, onError) {
    //login(email, password, onSuccess, onError) {
    axios
      //.post("https://reqres.in/api/login", { email: email, password: password })
      /*
      .post(
        "https://www.crm-castelfranci.it/testcrm/wsngl/users/api_login.php",
        { email: email, password: password }
      )
      */
      .post(
        "https://www.crm-castelfranci.it/testcrm/wsngl/users/api_login.php",
        {
          wsMode: wsMode,
          usrUsername: usrUsername,
          usrPassword: usrPassword,
          idDevice: idDevice
        }
      )

      .then(
        function(result) {
          console.log("Login effettuato con successo, token: ", result.data);
          onSuccess(result.data);
        },
        function(error) {
          console.error("Errore durante il login: ", error);
          //onError(error.response.data);
          onError(error);
        }
      );
  }
}
