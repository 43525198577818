import React from "react";

import I1SlideImage from "../../photos/sangiuseppe/eColliaminei-01.jpg";
import I2SlideImage from "../../photos/sspietroepaolo/eRoma-01.jpg";
import I3SlideImage from "../../photos/cuoreimmacolato/eGrotta-01.jpg";
import I4SlideImage from "../../photos/villaferretti/eMiano-01.jpg";
import I5SlideImage from "../../photos/sanvincenzodepaoli/eIsola-01.jpg";
import I6SlideImage from "../../photos/materdei/eCeccano-01.jpg";
import I7SlideImage from "../../photos/villaaurora/eAlanno-01.jpg";
import I8SlideImage from "../../photos/reginapacis/ePollena-01.jpg";
import I9SlideImage from "../../photos/sangennarello/eSangennarello-01.jpg";
import I10SlideImage from "../../photos/ponzano/ePonzano-01.jpg";

import { Carousel } from "react-responsive-carousel";
import ResponsiveImage from "./ResponsiveImage";

import "../../css/home/home.css";
//const dblocco = "d-block w-40";
//const dblocco = "img-responsive";
//const coloretesto = "#00f";

const Carosello = () => {
  return (
    <Carousel
      dynamicHeight
      autoPlay
      infiniteLoop
      showThumbs={false}
      showStatus={false}
      stopOnHover={false}
      transitionTime={4000}
      interval={5000}
    >
      <div>
        <ResponsiveImage src={I1SlideImage} width={1000} height={600} />

        <p className="legend">San Giuseppe - Colli Aminei - Napoli</p>
      </div>
      <div>
        <ResponsiveImage src={I2SlideImage} width={1000} height={600} />
        <p className="legend">SS. Pietro e Paolo - Roma</p>
      </div>
      <div>
        <ResponsiveImage src={I3SlideImage} width={1000} height={600} />
        <p className="legend">
          Cuore Immacolato di Maria - Grottaferrata (Roma)
        </p>
      </div>
      <div>
        <ResponsiveImage src={I4SlideImage} width={1000} height={600} />
        <p className="legend">Villa Ferretti - Miano - Napoli</p>
      </div>
      <div>
        <ResponsiveImage src={I5SlideImage} width={1000} height={600} />
        <p className="legend">S. Vincenzo de' Paoli - Isola del Liri (FR)</p>
      </div>
      <div>
        <ResponsiveImage src={I6SlideImage} width={1000} height={600} />
        <p className="legend">Mater Dei - Ceccano (FR)</p>
      </div>
      <div>
        <ResponsiveImage src={I7SlideImage} width={1000} height={600} />
        <p className="legend">Villa Aurora - Alanno (PE)</p>
      </div>
      <div>
        <ResponsiveImage src={I8SlideImage} width={1000} height={600} />
        <p className="legend">Regina Pacis - Pollena Trocchia (NA)</p>
      </div>
      <div>
        <ResponsiveImage src={I9SlideImage} width={1000} height={600} />
        <p className="legend">Pier Giorgio Frassati - Ottaviano (NA)</p>
      </div>
      <div>
        <ResponsiveImage src={I10SlideImage} width={1000} height={600} />
        <p className="legend">Maria Immacolata - Ponzano (TR)</p>
      </div>
    </Carousel>
  );
};
export default Carosello;
