import React, { useEffect } from "react";

//import photos from "./cuoreimmacolatophotos";
import I01 from "../../photos/sspietroepaolo/eRoma-01.jpg";
import I02 from "../../photos/sspietroepaolo/eRoma-02.jpg";
import I03 from "../../photos/sspietroepaolo/eRoma-03.jpg";
import I04 from "../../photos/sspietroepaolo/eRoma-04.jpg";
import I05 from "../../photos/sspietroepaolo/iRoma-01.jpg";
import I06 from "../../photos/sspietroepaolo/iRoma-02.jpg";
import I07 from "../../photos/sspietroepaolo/iRoma-03.jpg";
import I08 from "../../photos/sspietroepaolo/iRoma-03a.jpg";
import I09 from "../../photos/sspietroepaolo/iRoma-04.jpg";
import I10 from "../../photos/sspietroepaolo/iRoma-04a.jpg";
import I11 from "../../photos/sspietroepaolo/iRoma-04b.jpg";
import I12 from "../../photos/sspietroepaolo/iRoma-04c.jpg";
import I13 from "../../photos/sspietroepaolo/iRoma-05.jpg";
import I14 from "../../photos/sspietroepaolo/iRoma-06.jpg";
import I15 from "../../photos/sspietroepaolo/iRoma-07.jpg";
import I16 from "../../photos/sspietroepaolo/iRoma-07a.jpg";
import I17 from "../../photos/sspietroepaolo/iRoma-07b.jpg";
import I18 from "../../photos/sspietroepaolo/iRoma-07c.jpg";
import I19 from "../../photos/sspietroepaolo/iRoma-08.jpg";
import I20 from "../../photos/sspietroepaolo/iRoma-09.jpg";
import I21 from "../../photos/sspietroepaolo/iRoma-10.jpg";
import I22 from "../../photos/sspietroepaolo/iRoma-11.jpg";
import I23 from "../../photos/sspietroepaolo/iRoma-12.jpg";
import I24 from "../../photos/sspietroepaolo/iRoma-13.jpg";
import I25 from "../../photos/sspietroepaolo/iRoma-14.jpg";
import I26 from "../../photos/sspietroepaolo/iRoma-15.jpg";
import I27 from "../../photos/sspietroepaolo/iRoma-16.jpg";

import "../../css/home/home.css";
import { SRLWrapper } from "simple-react-lightbox";
import ResponsiveImage from "./ResponsiveImage";
import GalleriaLogo from "../../images/galleria.jpg";

import '../../i18n';
import { useTranslation } from 'react-i18next';

const options = {
  overlayColor: "rgb(25, 99, 136)",
  transitionTimingFunction: "ease-in-out",
  slideTransitionSpeed: 1000,
  buttonsIconPadding: "2px",
  buttonsIconColor: "rgb(255, 255, 255)",
  enablePanzoom: false,
  showThumbnails: false,
  hideControlsAfter: 0,
};

//...snip
//{ photos.map(({id, src, title, description}) => <img key={id} src={src} title={title} alt={description} />)

const SSPietroePaoloG = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const { t } = useTranslation();

  return (
    <div className="home-centro-h">
      <div className="row">
        <div className="col-sm-4 home-siamodasempre">
          <hr width="150" size="1" color="#20b2aa" align="center" />

          <h1>{t('sspietroepaolo001.label')}</h1>
          <h5>{t('sspietroepaolo002.label')}</h5>
          <div className="row">
            <div className="col-1" />
            <div className="col-5">
              <h5>Tel. 06 555262709</h5>
            </div>
          </div>
          <h5>Email roma@istitutosuorevisitazione.it</h5>

          <p></p>
          <hr width="150" size="1" color="#20b2aa" align="center" />
          <p></p>
        </div>
        <div className="col-sm-3">
          <ResponsiveImage src={I01} width={650} height={450} />
        </div>
        <div className="col-sm-5">
          <img
            alt=""
            src={GalleriaLogo}
            width="100"
            height="100"
            title={t('msg026.label')}
          />
          <hr width="300" size="5" color="#20b2aa" align="center" />

          <h1>
            <font color="#00b9fd">{t('msg024.label')}</font>
          </h1>
          <h3>
            <font color="#191970">{t('msg025.label')}</font>
          </h3>
          <p></p>
          <hr width="300" size="5" color="#20b2aa" align="center" />
          <p></p>
        </div>
      </div>

      <SRLWrapper options={options}>
        <div className="d-flex flex-wrap  position-relative w-100 h-100 align-items-center align-content-center">
          <div className="cornice-foto">
            <a href={I01} data-attribute="SRL">
              <img src={I01} width="40" height="40" alt="" />
            </a>
          </div>
          <div className="cornice-foto">
            <a href={I02} data-attribute="SRL">
              <img src={I02} width="40" height="40" alt="" />
            </a>
          </div>
          <div className="cornice-foto">
            <a href={I03} data-attribute="SRL">
              <img src={I03} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I04} data-attribute="SRL">
              <img src={I04} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I05} data-attribute="SRL">
              <img src={I05} alt="" />
            </a>
          </div>
          <div className="cornice-foto">
            <a href={I06} data-attribute="SRL">
              <img src={I06} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I07} data-attribute="SRL">
              <img src={I07} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I08} data-attribute="SRL">
              <img src={I08} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I09} data-attribute="SRL">
              <img src={I09} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I10} data-attribute="SRL">
              <img src={I10} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I11} data-attribute="SRL">
              <img src={I11} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I12} data-attribute="SRL">
              <img src={I12} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I13} data-attribute="SRL">
              <img src={I13} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I14} data-attribute="SRL">
              <img src={I14} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I15} data-attribute="SRL">
              <img src={I15} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I16} data-attribute="SRL">
              <img src={I16} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I17} data-attribute="SRL">
              <img src={I17} width="40" height="40" alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I18} data-attribute="SRL">
              <img src={I18} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I19} data-attribute="SRL">
              <img src={I19} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I20} data-attribute="SRL">
              <img src={I20} alt="" />
            </a>
          </div>
          <div className="cornice-foto">
            <a href={I21} data-attribute="SRL">
              <img src={I21} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I22} data-attribute="SRL">
              <img src={I22} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I23} data-attribute="SRL">
              <img src={I23} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I24} data-attribute="SRL">
              <img src={I24} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I25} data-attribute="SRL">
              <img src={I25} width="40" height="40" alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I26} data-attribute="SRL">
              <img src={I26} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I27} data-attribute="SRL">
              <img src={I27} alt="" />
            </a>
          </div>
        </div>
      </SRLWrapper>
    </div>
  );
};

export default SSPietroePaoloG;
