import React from "react";

import "../../../css/mainLayout/header/header.css";

//import Logo from "../../../images/regolo.png";
import Logo from "../../../images/logo.gif";

import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
//import ResponsiveImage from "../../home/ResponsiveImage";
import Roma1 from "../../../photos/sspietroepaolo/eRoma-01.jpg";
import Grotta1 from "../../../photos/cuoreimmacolato/eGrotta-01.jpg";
import Isola1 from "../../../photos/sanvincenzodepaoli/eIsola-01.jpg";
import Ceccano1 from "../../../photos/materdei/eCeccano-01.jpg";
import Colliaminei1 from "../../../photos/sangiuseppe/eColliaminei-01.jpg";
import Miano1 from "../../../photos/villaferretti/eMiano-01.jpg";
import Alanno1 from "../../../photos/villaaurora/eAlanno-01.jpg";
import Brasile from "../../../photos/brasile/brasile.jpg";
import Ecuador from "../../../photos/ecuador/ecuador.jpg";
import Africa from "../../../photos/africa/africa.jpg";
import Pollena1 from "../../../photos/reginapacis/ePollena-01.jpg";
import Portici1 from "../../../photos/portici/ePortici-01.jpg";
import Caiazzo1 from "../../../photos/caiazzo/eCaiazzo-01.jpg";
import Treviso1 from "../../../photos/ponzano/ePonzano-01.jpg";

import InfanziaBarra from "../../../photos/scuole/infanziaBarra-01.jpg";
import PrimariaBarra from "../../../photos/scuole/primariaBarra-01.jpg";
import InfanziaIsola from "../../../photos/scuole/infanziaIsola-01.jpg";
import InfanziaSanGen from "../../../photos/sangennarello/eSangennarello-01.jpg";
//import YouTube from "../../../images/youtube.jpg";

import ResponsiveImage from "../../home/ResponsiveImage";

import "../../../i18n";

import LanguageSelector from "../../../LanguageSelector";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();

  return (
    <div className="header">
      <Navbar collapseOnSelect expand="lg" className="my-bg-success">
        <Navbar.Brand as={Link} to="/">
          <img alt="" src={Logo} width="40" height="50" />
          <span className="nav-brand-text">{t("header000.label")}</span>
          <span>
            <LanguageSelector />
          </span>
        </Navbar.Brand>
        {/*
        <span className="navbar-nav ml-auto my-language-selector">
          <LanguageSelector />
        </span>
        */}
      </Navbar>

      <Navbar collapseOnSelect expand="lg" variant="light">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link eventKey="1" as={Link} to="/">
              {t("header100.label")}
            </Nav.Link>
            <Nav.Link eventKey="1" as={Link} to="/chisiamo">
              {t("header200.label")}
            </Nav.Link>
            <NavDropdown title={t("header300.label")} id="basic-nav-dropdown">
              <NavDropdown.Item disabled>
                <i>{t("header301.label")}</i>
              </NavDropdown.Item>

              <NavDropdown.Item disabled>
                <div className="my-header-dropdown">{t("header302.label")}</div>
              </NavDropdown.Item>

              <NavDropdown.Item eventKey="10" as={Link} to="/cas-sangiuseppe">
                <div className="row">
                  <div className="col-auto">
                    <ul className="nav navbar-nav navbar-right ml-auto">
                      <ResponsiveImage
                        src={Colliaminei1}
                        width={40}
                        height={30}
                      />
                      &nbsp;{t("header303.label")}
                    </ul>
                  </div>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="11" as={Link} to="/tut-sangiuseppe">
                <div className="row">
                  <div className="col-auto">
                    <ul className="nav navbar-nav navbar-right ml-auto">
                      <ResponsiveImage
                        src={Colliaminei1}
                        width={40}
                        height={30}
                      />
                      &nbsp;{t("header304.label")}
                    </ul>
                  </div>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="12" as={Link} to="/rsa-sangiuseppe">
                <div className="row">
                  <div className="col-auto">
                    <ul className="nav navbar-nav navbar-right ml-auto">
                      <ResponsiveImage
                        src={Colliaminei1}
                        width={40}
                        height={30}
                      />
                      &nbsp;{t("header305.label")}
                    </ul>
                  </div>
                </div>
              </NavDropdown.Item>

              <NavDropdown.Item eventKey="13" as={Link} to="/villaferretti">
                <div className="row">
                  <div className="col-auto">
                    <ul className="nav navbar-nav navbar-right ml-auto">
                      <ResponsiveImage src={Miano1} width={40} height={30} />
                      &nbsp;{t("header306.label")}
                    </ul>
                  </div>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item disabled>
                <div className="my-header-dropdown">{t("header307.label")}</div>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="14" as={Link} to="/sspietroepaolo">
                <div className="row">
                  <div className="col-auto">
                    <ul className="nav navbar-nav navbar-right ml-auto">
                      <ResponsiveImage src={Roma1} width={40} height={30} />
                      &nbsp;{t("header308.label")}
                    </ul>
                  </div>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="15" as={Link} to="/cuoreimmacolato">
                <div className="row">
                  <div className="col-auto">
                    <ul className="nav navbar-nav navbar-right ml-auto">
                      <ResponsiveImage src={Grotta1} width={40} height={30} />
                      &nbsp;{t("header309.label")}
                    </ul>
                  </div>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey="16"
                as={Link}
                to="/sanvincenzodepaoli"
              >
                <div className="row">
                  <div className="col-auto">
                    <ul className="nav navbar-nav navbar-right ml-auto">
                      <ResponsiveImage src={Isola1} width={40} height={30} />
                      &nbsp;{t("header310.label")}
                    </ul>
                  </div>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="17" as={Link} to="/materdei">
                <div className="row">
                  <div className="col-auto">
                    <ul className="nav navbar-nav navbar-right ml-auto">
                      <ResponsiveImage src={Ceccano1} width={40} height={30} />
                      &nbsp;{t("header311.label")}
                    </ul>
                  </div>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item disabled>
                <div className="my-header-dropdown">{t("header312.label")}</div>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="18" as={Link} to="/villaaurora">
                <div className="row">
                  <div className="col-auto">
                    <ul className="nav navbar-nav navbar-right ml-auto">
                      <ResponsiveImage src={Alanno1} width={40} height={30} />
                      &nbsp;{t("header313.label")}
                    </ul>
                  </div>
                </div>
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title={t("header400.label")} id="basic-nav-dropdown">
              <NavDropdown.Item disabled>
                <i>{t("header401.label")}</i>
              </NavDropdown.Item>

              <NavDropdown.Item eventKey="20" as={Link} to="/brasile">
                <div className="row">
                  <div className="col-auto">
                    <ul className="nav navbar-nav navbar-right ml-auto">
                      <ResponsiveImage src={Brasile} width={40} height={30} />
                      &nbsp;{t("header402.label")}
                    </ul>
                  </div>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="21" as={Link} to="/ecuador">
                <div className="row">
                  <div className="col-auto">
                    <ul className="nav navbar-nav navbar-right ml-auto">
                      <ResponsiveImage src={Ecuador} width={40} height={30} />
                      &nbsp;{t("header403.label")}
                    </ul>
                  </div>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="22" as={Link} to="/africa">
                <div className="row">
                  <div className="col-auto">
                    <ul className="nav navbar-nav navbar-right ml-auto">
                      <ResponsiveImage src={Africa} width={40} height={30} />
                      &nbsp;{t("header404.label")}
                    </ul>
                  </div>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item disabled>
                <div className="my-header-dropdown">{t("header405.label")}</div>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="23"></NavDropdown.Item>
              <div className="row">
                <a
                  className="nav navbar-nav navbar-right mx-auto"
                  href="https://youtu.be/1yiJZMM4RVg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("header406.label")}
                </a>
              </div>
            </NavDropdown>

            <NavDropdown title={t("header500.label")} id="basic-nav-dropdown">
              <NavDropdown.Item disabled>
                <i>{t("header501.label")}</i>
              </NavDropdown.Item>

              <NavDropdown.Item eventKey="30" as={Link} to="/pollena">
                <div className="row">
                  <div className="col-auto">
                    <ul className="nav navbar-nav navbar-right ml-auto">
                      <ResponsiveImage src={Pollena1} width={40} height={30} />
                      &nbsp;{t("header502.label")}
                    </ul>
                  </div>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="31" as={Link} to="/portici">
                <div className="row">
                  <div className="col-auto">
                    <ul className="nav navbar-nav navbar-right ml-auto">
                      <ResponsiveImage src={Portici1} width={40} height={30} />
                      &nbsp;{t("header503.label")}
                    </ul>
                  </div>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="32" as={Link} to="/caiazzo">
                <div className="row">
                  <div className="col-auto">
                    <ul className="nav navbar-nav navbar-right ml-auto">
                      <ResponsiveImage src={Caiazzo1} width={40} height={30} />
                      &nbsp;{t("header504.label")}
                    </ul>
                  </div>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="33" as={Link} to="/treviso">
                <div className="row">
                  <div className="col-auto">
                    <ul className="nav navbar-nav navbar-right ml-auto">
                      <ResponsiveImage src={Treviso1} width={40} height={30} />
                      &nbsp;{t("header505.label")}
                    </ul>
                  </div>
                </div>
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link eventKey="4" as={Link} to="/pastorale">
              {t("header600.label")}
            </Nav.Link>

            <NavDropdown title={t("header700.label")} id="basic-nav-dropdown">
              <NavDropdown.Item disabled>
                <i>{t("header701.label")}</i>
              </NavDropdown.Item>

              <NavDropdown.Item eventKey="40" as={Link} to="/scuola">
                <div className="row">
                  <div className="col-auto">
                    <ul className="nav navbar-nav navbar-right ml-auto">
                      <ResponsiveImage
                        src={InfanziaBarra}
                        width={40}
                        height={30}
                      />
                      &nbsp;{t("header702.label")}
                    </ul>
                  </div>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="41" as={Link} to="/scuola">
                <div className="row">
                  <div className="col-auto">
                    <ul className="nav navbar-nav navbar-right ml-auto">
                      <ResponsiveImage
                        src={PrimariaBarra}
                        width={40}
                        height={30}
                      />
                      &nbsp;{t("header703.label")}
                    </ul>
                  </div>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey="42"
                as={Link}
                to="/scuolainfanziasangen"
              >
                <div className="row">
                  <div className="col-auto">
                    <ul className="nav navbar-nav navbar-right ml-auto">
                      <ResponsiveImage
                        src={InfanziaSanGen}
                        width={40}
                        height={30}
                      />
                      &nbsp;{t("header704.label")}
                    </ul>
                  </div>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey="43"
                as={Link}
                to="/scuolainfanziaisola"
              >
                <div className="row">
                  <div className="col-auto">
                    <ul className="nav navbar-nav navbar-right ml-auto">
                      <ResponsiveImage
                        src={InfanziaIsola}
                        width={40}
                        height={30}
                      />
                      &nbsp;{t("header705.label")}
                    </ul>
                  </div>
                </div>
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link eventKey="6" as={Link} to="/dovesiamo">
              {t("header800.label")}
            </Nav.Link>
            <Nav.Link eventKey="7" as={Link} to="/contatti">
              {t("header900.label")}
            </Nav.Link>
            <Nav.Link eventKey="8" as={Link} to="/privacy">
              {t("header1000.label")}
            </Nav.Link>
            <Nav.Link eventKey="9" as={Link} to="/xmille">
              5xmille
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        {/*
        <span className="navbar-nav ml-auto my-language-selector">
          <LanguageSelector />
        </span>
        */}
      </Navbar>
    </div>
  );
};
export default Header;
