import React from "react";
import CasaMadre from "../../images/CasaMadre.png";
import Roma1 from "../../photos/sspietroepaolo/eRoma-01.jpg";
import Grotta1 from "../../photos/cuoreimmacolato/eGrotta-01.jpg";
import ColliAminei1 from "../../photos/sangiuseppe/eColliaminei-01.jpg";
import Miano1 from "../../photos/villaferretti/eMiano-01.jpg";
import Isola1 from "../../photos/sanvincenzodepaoli/eIsola-01.jpg";
import Ceccano1 from "../../photos/materdei/eCeccano-01.jpg";
import Alanno1 from "../../photos/villaaurora/eAlanno-01.jpg";
import Pollena1 from "../../photos/reginapacis/ePollena-01.jpg";
import Sangennarello1 from "../../photos/sangennarello/eSangennarello-01.jpg";
import Ponzano1 from "../../photos/ponzano/ePonzano-01.jpg";
import Brasile from "../../photos/brasile/brasile.jpg";
import Ecuador from "../../photos/ecuador/ecuador.jpg";
import Africa from "../../photos/africa/africa.jpg";

import ResponsiveImage from "./ResponsiveImage";
import LogoFacebook from "../../images/facebook.png";
import "../../css/home/home.css";

import '../../i18n';
import { useTranslation } from 'react-i18next';

//iframe1=Casa Madre
const iframe1 =
  '<iframe height="300" style="width: 100%;" scrolling="no" src="https://maps.google.com/maps?width=700&amp;height=500&amp;hl=en&amp;q=corso%20bruno%20buozzi%20174%20napoli+(Titolo)&amp;ie=UTF8&amp;t=k&amp;z=17&amp;iwloc=B&amp;output=embed" frameborder="no" marginheight="0" marginwidth="0"</iframe>';

function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
    />
  );
}
//iframe2=Roma
const iframe2 =
  '<iframe height="300" style="width: 100%;" scrolling="no" src="https://maps.google.com/maps?width=700&amp;height=450&amp;hl=en&amp;q=via%20della%20magliana%20173%20roma+(Titolo)&amp;ie=UTF8&amp;t=k&amp;z=16&amp;iwloc=B&amp;output=embed" frameborder="no" marginheight="0" marginwidth="0"</iframe>';

//iframe3=Grottaferrata
const iframe3 =
  '<iframe height="300" style="width: 100%;" scrolling="no" src="https://maps.google.com/maps?width=700&amp;height=450&amp;hl=en&amp;q=via%20del%20colli%2013%20grottaferrata+(Titolo)&amp;ie=UTF8&amp;t=k&amp;z=16&amp;iwloc=B&amp;output=embed" frameborder="no" marginheight="0" marginwidth="0"</iframe>';

//iframe4=Colli Aminei
const iframe4 =
  '<iframe height="300" style="width: 100%;" scrolling="no" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=viale%20colli%20aminei%2094%20napoli+(Titolo)&amp;ie=UTF8&amp;t=k&amp;z=16&amp;iwloc=B&amp;output=embed" frameborder="no" marginheight="0" marginwidth="0"</iframe>';

//iframe5=Miano
const iframe5 =
  '<iframe height="300" style="width: 100%;" scrolling="no" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=via%20miano%2071%20napoli+(Titolo)&amp;ie=UTF8&amp;t=k&amp;z=16&amp;iwloc=B&amp;output=embed" frameborder="no" marginheight="0" marginwidth="0"</iframe>';

//iframe6=Isola
const iframe6 =
  '<iframe height="300" style="width: 100%;" scrolling="no" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=via%20selva%2045%20isola%20del%20liri+(Titolo)&amp;ie=UTF8&amp;t=k&amp;z=16&amp;iwloc=B&amp;output=embed" frameborder="no" marginheight="0" marginwidth="0"</iframe>';

//iframe7=Ceccano
const iframe7 =
  '<iframe height="300" style="width: 100%;" scrolling="no" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=via%20magenta%2059%20ceccano+(Titolo)&amp;ie=UTF8&amp;t=k&amp;z=16&amp;iwloc=B&amp;output=embed" frameborder="no" marginheight="0" marginwidth="0"</iframe>';

//iframe8=Alanno
const iframe8 =
  '<iframe height="300" style="width: 100%;" scrolling="no" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=via%20circonterranea%2010%20alanno+(Titolo)&amp;ie=UTF8&amp;t=k&amp;z=15&amp;iwloc=B&amp;output=embed" frameborder="no" marginheight="0" marginwidth="0"</iframe>';

//iframe9=Pollena Trocchia
const iframe9 =
  '<iframe height="300" style="width: 100%;" scrolling="no" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=via%20caracciolo%2010%20pollena%20trocchia+(Titolo)&amp;ie=UTF8&amp;t=k&amp;z=16&amp;iwloc=B&amp;output=embed" frameborder="no" marginheight="0" marginwidth="0"</iframe>';

//iframe10=San Gennarello di Ottaviano
const iframe10 =
  '<iframe height="300" style="width: 100%;" scrolling="no" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=via%20sarno%2020%20ottaviano+(Titolo)&amp;ie=UTF8&amp;t=k&amp;z=17&amp;iwloc=B&amp;output=embed" frameborder="no" marginheight="0" marginwidth="0"</iframe>';

//iframe11=Ponzano - Treviso
const iframe11 =
  '<iframe height="300" style="width: 100%;" scrolling="no" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=via%20santa%20bartolomea%201%20ponzano+(Titolo)&amp;ie=UTF8&amp;t=k&amp;z=17&amp;iwloc=B&amp;output=embed" frameborder="no" marginheight="0" marginwidth="0"</iframe>';

//iframe12=Brasile
const iframe12 =
  '<iframe height="300" style="width: 100%;" scrolling="no" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=Alagoas%20sergipe%20+(Titolo)&amp;ie=UTF8&amp;t=k&amp;z=7&amp;iwloc=B&amp;output=embed" frameborder="no" marginheight="0" marginwidth="0"</iframe>';

//iframe13=Ecuador
const iframe13 =
  '<iframe height="300" style="width: 100%;" scrolling="no" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=BAHIA%20DE%20CARAQUEZ%20(MANAB%C3%8C)+(Titolo)&amp;ie=UTF8&amp;t=k&amp;z=12&amp;iwloc=B&amp;output=embed" frameborder="no" marginheight="0" marginwidth="0"</iframe>';

//iframe14=Africa
const iframe14 =
  '<iframe height="300" style="width: 100%;" scrolling="no" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=Lom%C3%A8%20togo+(Titolo)&amp;ie=UTF8&amp;t=k&amp;z=7&amp;iwloc=B&amp;output=embed" frameborder="no" marginheight="0" marginwidth="0"</iframe>';

const Dovesiamo = () => {

  const { t } = useTranslation();

  return (
    <div className="home-centro-h">
      <div className="home-content">
        <h1 className="home-top20-c">
        {t('dovesiamo001.label')}
        </h1>
        <p></p>
        <div className="row">
          <div className="col-sm-3 home-siamodasempre">
            <hr width="150" size="1" color="#20b2aa" align="center" />

            <h1>{t('chisiamo001.label')}</h1>
            <h5>{t('chisiamo002.label')}</h5>
            <div className="row">
              <div className="col-1" />

              <div className="col-7">
                <h5>Tel. 081 5729117</h5>
              </div>
              <div className="col-1">
                <a
                  href="https://www.facebook.com/poverefiglie.dellavisitazione/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="facebook"
                    src={LogoFacebook}
                    className="logo-facebook"
                    width="30"
                    height="30"
                  />
                </a>
              </div>
            </div>
            <h6>Email: casamadre@istitutosuorevisitazione.it</h6>

            <p></p>
            <hr width="150" size="1" color="#20b2aa" align="center" />
            <p></p>
          </div>
          <div className="col-sm-4">
            <ResponsiveImage src={CasaMadre} width={600} height={400} />
          </div>
          <div className="col-sm-5">
            <Iframe iframe={iframe1} />
          </div>
        </div>
        <p></p>
        <div className="row">
          <div className="col-sm-3 home-siamodasempre">
            <hr width="150" size="1" color="#20b2aa" align="center" />

            <h2>{t('dovesiamo002.label')}</h2>
            <h5>viale Colli Aminei 94 - 80131 Napoli</h5>
            <div className="row">
              <div className="col-1" />

              <div className="col-7">
                <h5>Tel. 081 7430011</h5>
              </div>
              <div className="col-1">
                <a
                  href="https://www.facebook.com/Povere-Figlie-della-Visitazione-di-Maria-Istituto-San-Giuseppe-515197455556884"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="facebook"
                    src={LogoFacebook}
                    className="logo-facebook"
                    width="30"
                    height="30"
                  />
                </a>
              </div>
            </div>
            <h6>Email: colliaminei@istitutosuorevisitazione.it</h6>

            <p></p>
            <hr width="150" size="1" color="#20b2aa" align="center" />
            <p></p>
          </div>
          <div className="col-sm-4">
            <ResponsiveImage src={ColliAminei1} width={600} height={400} />
          </div>
          <div className="col-sm-5">
            <Iframe iframe={iframe4} />
          </div>
        </div>
        <p></p>
        <div className="row">
          <div className="col-sm-3 home-siamodasempre">
            <hr width="150" size="1" color="#20b2aa" align="center" />

            <h2>{t('villaferretti001.label')}</h2>
            <h5>{t('villaferretti002.label')}</h5>
            <div className="row">
              <div className="col-1" />

              <div className="col-7">
                <h5>Tel. 081 7415928</h5>
              </div>
              <div className="col-1">
                <a
                  href="https://www.facebook.com/poverefiglie.dellavisitazione/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="facebook"
                    src={LogoFacebook}
                    className="logo-facebook"
                    width="30"
                    height="30"
                  />
                </a>
              </div>
            </div>
            <h6>Email: miano@istitutosuorevisitazione.it</h6>

            <p></p>
            <hr width="150" size="1" color="#20b2aa" align="center" />
            <p></p>
          </div>
          <div className="col-sm-4">
            <ResponsiveImage src={Miano1} width={600} height={400} />
          </div>
          <div className="col-sm-5">
            <Iframe iframe={iframe5} />
          </div>
        </div>
        <p></p>
        <div className="row">
          <div className="col-sm-3 home-siamodasempre">
            <hr width="150" size="1" color="#20b2aa" align="center" />

            <h2>{t('pollena001.label')}</h2>
            <h5>via Caracciolo 10 - 80040 Pollena Trocchia (NA)</h5>
            <div className="row">
              <div className="col-1" />

              <div className="col-7">
                <h5>Tel. 081 8971554</h5>
              </div>
              <div className="col-1">
                <a
                  href="https://www.facebook.com/poverefiglie.dellavisitazione/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="facebook"
                    src={LogoFacebook}
                    className="logo-facebook"
                    width="30"
                    height="30"
                  />
                </a>
              </div>
            </div>
            <h6>Email: pollena@istitutosuorevisitazione.it</h6>

            <p></p>
            <hr width="150" size="1" color="#20b2aa" align="center" />
            <p></p>
          </div>
          <div className="col-sm-4">
            <ResponsiveImage src={Pollena1} width={600} height={400} />
          </div>
          <div className="col-sm-5">
            <Iframe iframe={iframe9} />
          </div>
        </div>
        <p></p>
        <div className="row">
          <div className="col-sm-3 home-siamodasempre">
            <hr width="150" size="1" color="#20b2aa" align="center" />

            <h2>{t('scuolainfanziasangen001.label')}</h2>
            <h5>via Sarno 20 - 80044 Ottaviano (NA)</h5>
            <div className="row">
              <div className="col-1" />

              <div className="col-7">
                <h5>Tel. 081 8273974</h5>
              </div>
              <div className="col-1">
                <a
                  href="https://www.facebook.com/poverefiglie.dellavisitazione/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="facebook"
                    src={LogoFacebook}
                    className="logo-facebook"
                    width="30"
                    height="30"
                  />
                </a>
              </div>
            </div>
            <h6>Email: sangennarello@istitutosuorevisitazione.it</h6>

            <p></p>
            <hr width="150" size="1" color="#20b2aa" align="center" />
            <p></p>
          </div>
          <div className="col-sm-4">
            <ResponsiveImage src={Sangennarello1} width={600} height={400} />
          </div>
          <div className="col-sm-5">
            <Iframe iframe={iframe10} />
          </div>
        </div>
        <p></p>
        <div className="row">
          <div className="col-sm-3 home-siamodasempre">
            <hr width="150" size="1" color="#20b2aa" align="center" />

            <h2>{t('sspietroepaolo001.label')}</h2>
            <h5>{t('sspietroepaolo002.label')}</h5>
            <div className="row">
              <div className="col-1" />

              <div className="col-7">
                <h5>Tel. 06 555262709</h5>
              </div>
              <div className="col-1">
                <a
                  href="https://www.facebook.com/poverefiglie.dellavisitazione/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="facebook"
                    src={LogoFacebook}
                    className="logo-facebook"
                    width="30"
                    height="30"
                  />
                </a>
              </div>
            </div>
            <h6>Email: roma@istitutosuorevisitazione.it</h6>

            <p></p>
            <hr width="150" size="1" color="#20b2aa" align="center" />
            <p></p>
          </div>
          <div className="col-sm-4">
            <ResponsiveImage src={Roma1} width={600} height={400} />
          </div>
          <div className="col-sm-5">
            <Iframe iframe={iframe2} />
          </div>
        </div>
        <p></p>
        <div className="row">
          <div className="col-sm-3 home-siamodasempre">
            <hr width="150" size="1" color="#20b2aa" align="center" />

            <h2>{t('cuoreimmacolato001.label')}</h2>
            <h5>{t('cuoreimmacolato002.label')}</h5>
            <div className="row">
              <div className="col-1" />

              <div className="col-7">
                <h5>Tel. 06 9456398</h5>
              </div>
              <div className="col-1">
                <a
                  href="https://www.facebook.com/poverefiglie.dellavisitazione/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="facebook"
                    src={LogoFacebook}
                    className="logo-facebook"
                    width="30"
                    height="30"
                  />
                </a>
              </div>
            </div>
            <h6>Email: grottaferrata@istitutosuorevisitazione.it</h6>

            <p></p>
            <hr width="150" size="1" color="#20b2aa" align="center" />
            <p></p>
          </div>
          <div className="col-sm-4">
            <ResponsiveImage src={Grotta1} width={600} height={400} />
          </div>
          <div className="col-sm-5">
            <Iframe iframe={iframe3} />
          </div>
        </div>
        <p></p>
        <div className="row">
          <div className="col-sm-3 home-siamodasempre">
            <hr width="150" size="1" color="#20b2aa" align="center" />

            <h2>{t('sanvincenzodepaoli001.label')}</h2>
            <h5>via Selva 45 - 03036 Isola del Liri (FR)</h5>
            <div className="row">
              <div className="col-1" />

              <div className="col-7">
                <h5>Tel. 0776 808119</h5>
              </div>
              <div className="col-1">
                <a
                  href="https://www.facebook.com/poverefiglie.dellavisitazione/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="facebook"
                    src={LogoFacebook}
                    className="logo-facebook"
                    width="30"
                    height="30"
                  />
                </a>
              </div>
            </div>
            <h6>Email: isola@istitutosuorevisitazione.it</h6>

            <p></p>
            <hr width="150" size="1" color="#20b2aa" align="center" />
            <p></p>
          </div>
          <div className="col-sm-4">
            <ResponsiveImage src={Isola1} width={600} height={400} />
          </div>
          <div className="col-sm-5">
            <Iframe iframe={iframe6} />
          </div>
        </div>
        <p></p>
        <div className="row">
          <div className="col-sm-3 home-siamodasempre">
            <hr width="150" size="1" color="#20b2aa" align="center" />

            <h2>{t('materdei001.label')}</h2>
            <h5>via Magenta 59 - 03342 Ceccano (FR)</h5>
            <div className="row">
              <div className="col-1" />

              <div className="col-7">
                <h5>Tel. 0775 500291</h5>
              </div>
              <div className="col-1">
                <a
                  href="https://www.facebook.com/poverefiglie.dellavisitazione/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="facebook"
                    src={LogoFacebook}
                    className="logo-facebook"
                    width="30"
                    height="30"
                  />
                </a>
              </div>
            </div>
            <h6>Email: ceccano@istitutosuorevisitazione.it</h6>

            <p></p>
            <hr width="150" size="1" color="#20b2aa" align="center" />
            <p></p>
          </div>
          <div className="col-sm-4">
            <ResponsiveImage src={Ceccano1} width={600} height={400} />
          </div>
          <div className="col-sm-5">
            <Iframe iframe={iframe7} />
          </div>
        </div>
        <p></p>
        <div className="row">
          <div className="col-sm-3 home-siamodasempre">
            <hr width="150" size="1" color="#20b2aa" align="center" />

            <h2>{t('villaaurora001.label')}</h2>
            <h5>via Circonterranea 10 - 65020 Alanno (PE)</h5>
            <div className="row">
              <div className="col-1" />

              <div className="col-7">
                <h5>Tel. 085 8573181</h5>
              </div>
              <div className="col-1">
                <a
                  href="https://www.facebook.com/poverefiglie.dellavisitazione/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="facebook"
                    src={LogoFacebook}
                    className="logo-facebook"
                    width="30"
                    height="30"
                  />
                </a>
              </div>
            </div>
            <h6>Email: alanno@istitutosuorevisitazione.it</h6>

            <p></p>
            <hr width="150" size="1" color="#20b2aa" align="center" />
            <p></p>
          </div>
          <div className="col-sm-4">
            <ResponsiveImage src={Alanno1} width={600} height={400} />
          </div>
          <div className="col-sm-5">
            <Iframe iframe={iframe8} />
          </div>
        </div>
        <p></p>
        <div className="row">
          <div className="col-sm-3 home-siamodasempre">
            <hr width="150" size="1" color="#20b2aa" align="center" />

            <h2>{t('treviso001.label')}</h2>
            <h5>via S. Bartolomea 1 - 31050 Ponzano (TR)</h5>
            <div className="row">
              <div className="col-1" />

              <div className="col-7">
                <h5>Tel. 0422 9630321</h5>
              </div>
              <div className="col-1">
                <a
                  href="https://www.facebook.com/poverefiglie.dellavisitazione/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="facebook"
                    src={LogoFacebook}
                    className="logo-facebook"
                    width="30"
                    height="30"
                  />
                </a>
              </div>
            </div>
            <h6>Email: treviso@istitutosuorevisitazione.it</h6>

            <p></p>
            <hr width="150" size="1" color="#20b2aa" align="center" />
            <p></p>
          </div>
          <div className="col-sm-4">
            <ResponsiveImage src={Ponzano1} width={600} height={400} />
          </div>
          <div className="col-sm-5">
            <Iframe iframe={iframe11} />
          </div>
        </div>
        <p></p>
        <div className="row">
          <div className="col-sm-3 home-siamodasempre">
            <hr width="150" size="1" color="#20b2aa" align="center" />

            <h2>{t('brasile001.label')}</h2>
            <h5>Maceiò e Barra Nova (Alagoas)</h5>
            <h5>Garça Torta (Maceiò - Alagoas)</h5>
            <h5>Santana do Sao Francisco (Sergipe)</h5>
            <h5>Feira de Santana (Bahia)</h5>
            <div className="row">
              <div className="col-1" />

              <div className="col-7"></div>
              <div className="col-1">
                <a
                  href="https://www.facebook.com/cbn.centrobarranova"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="facebook"
                    src={LogoFacebook}
                    className="logo-facebook"
                    width="30"
                    height="30"
                  />
                </a>
              </div>
            </div>
            <h6>Email: brasile@istitutosuorevisitazione.it</h6>

            <p></p>
            <hr width="150" size="1" color="#20b2aa" align="center" />
            <p></p>
          </div>
          <div className="col-sm-4">
            <ResponsiveImage src={Brasile} width={600} height={400} />
          </div>
          <div className="col-sm-5">
            <Iframe iframe={iframe12} />
          </div>
        </div>
        <p></p>
        <div className="row">
          <div className="col-sm-3 home-siamodasempre">
            <hr width="150" size="1" color="#20b2aa" align="center" />

            <h2>{t('ecuador001.label')}</h2>
            <h5>Bahia de Caraquez (Manabì)</h5>
            <h5>San Vicente (Manabì)</h5>
            <div className="row">
              <div className="col-1" />

              <div className="col-7"></div>
              <div className="col-1">
                <a
                  href="https://www.facebook.com/poverefiglie.dellavisitazione/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="facebook"
                    src={LogoFacebook}
                    className="logo-facebook"
                    width="30"
                    height="30"
                  />
                </a>
              </div>
            </div>
            <h6>Email: ecuador@istitutosuorevisitazione.it</h6>

            <p></p>
            <hr width="150" size="1" color="#20b2aa" align="center" />
            <p></p>
          </div>
          <div className="col-sm-4">
            <ResponsiveImage src={Ecuador} width={600} height={400} />
          </div>
          <div className="col-sm-5">
            <Iframe iframe={iframe13} />
          </div>
        </div>
        <p></p>
        <div className="row">
          <div className="col-sm-3 home-siamodasempre">
            <hr width="150" size="1" color="#20b2aa" align="center" />

            <h2>{t('africa001.label')}</h2>
            <h5>Lomè (Togo)</h5>
            <h5>Benin</h5>
            <div className="row">
              <div className="col-1" />

              <div className="col-7"></div>
              <div className="col-1">
                <a
                  href="https://www.facebook.com/poverefiglie.dellavisitazione/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="facebook"
                    src={LogoFacebook}
                    className="logo-facebook"
                    width="30"
                    height="30"
                  />
                </a>
              </div>
            </div>
            <h6>Email: africa@istitutosuorevisitazione.it</h6>

            <p></p>
            <hr width="150" size="1" color="#20b2aa" align="center" />
            <p></p>
          </div>
          <div className="col-sm-4">
            <ResponsiveImage src={Africa} width={600} height={400} />
          </div>
          <div className="col-sm-5">
            <Iframe iframe={iframe14} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dovesiamo;
