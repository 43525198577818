import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

export default function MailForm() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const { register, errors, handleSubmit } = useForm();
  const onSubmit = (data, e) => {
    //e.target.reset(); // reset after form submit
    /*
    alert(
      "Cognome: " +
        data.cognome +
        " Nome: " +
        data.nome +
        " email: " +
        data.email +
        " msg: " +
        data.messaggio
    );
*/

    axios
      .post("https://localhost:3002/send", {
        xsCognome: data.cognome,
        xsNome: data.nome,
        xsEmail: data.email,
        xsMessaggio: data.messaggio,
      })

      .then(
        function (result) {
          alert("Email inviata con successo");
        },
        function (error) {
          alert("Errore nell'invio della email");
        }
      );
    e.target.reset(); // reset after form submit

    // alert("ok inviati");
  };
  console.log(errors);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="container">
        <div className="row">
          <div className="col-lg-3" />

          <div className="col-lg-6 home-siamodasempre">
            <h5>Compila il form per inviarci una Email</h5>
            <div>
              <input
                placeholder="Nome"
                type="text"
                size="58"
                name="nome"
                ref={register({ required: true, maxLength: 80 })}
              />
              <p></p>

              <input
                placeholder="Cognome"
                type="text"
                size="58"
                name="cognome"
                ref={register({ required: true, maxLength: 80 })}
              />
              <p></p>

              <input
                placeholder="Il tuo indirizzo Email"
                type="text"
                size="58"
                name="email"
                ref={register({
                  required: false,
                  pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
              <p></p>

              <textarea
                rows="6"
                cols="58"
                placeholder="Messaggio"
                size="58"
                name="messaggio"
                ref={register}
              />

              <label>
                Dichiaro di aver letto l'informativa privacy (art. 12 GDPR)...
              </label>
              <input
                type="checkbox"
                name="informativa"
                value="Yes"
                ref={register({ required: true })}
              />

              <label>
                Esprimo consenso al trattamento dei dati personali..............
              </label>
              <input
                type="checkbox"
                name="consenso"
                value="Yes"
                ref={register({ required: true })}
              />
            </div>
            <div>
              <input type="submit" value="Invia" />
            </div>
          </div>
          <div className="col-lg-3" />
        </div>
      </div>
    </form>
  );
}
