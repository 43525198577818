import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

import '../../i18n';
import { useTranslation } from 'react-i18next';

var mode = "Cwr";
var token = "&CCXFHGWJ(F18QZCMUJ1";

export default function NameForm() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const { register, errors, handleSubmit } = useForm();
  const onSubmit = (data, e) => {
    //e.target.reset(); // reset after form submit

    axios
      .post("https://www.comapo.it/regolo/wsapi/webgbl/api_webreq1.php", {
        wsMode: mode,
        wsTokenLogin: token,
        crmwrqCognome: data.cognome,
        crmwrqNome: data.nome,
        crmwrqTel1: data.telefono,
        crmwrqMobile1: data.cellulare,
        crmwrqEmail1: data.email,
        crmwrqNota: data.messaggio,
      })

      .then(
        function (result) {
          alert("Invio dati effettuato con successo");
        },
        function (error) {
          alert("Errore nell'invio dei dati");
        }
      );
    e.target.reset(); // reset after form submit

    // alert("ok inviati");
  };
  console.log(errors);

  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="container">
        <div className="row">
          <div className="col-lg-3" />

          <div className="col-lg-6 home-siamodasempre">
            <h5>
              <i>{t('nameform001.label')}</i>
            </h5>
            <div>
              <input
                className="form-control"
                placeholder={t('nameform002.label')}
                type="text"
                size="58"
                name="nome"
                ref={register({ required: true, maxLength: 80 })}
              />
              <p></p>

              <input
                className="form-control"
                placeholder={t('nameform003.label')}
                type="text"
                size="58"
                name="cognome"
                ref={register({ required: true, maxLength: 80 })}
              />
              <p></p>

              <input
                className="form-control"
                placeholder="Email"
                type="text"
                size="58"
                name="email"
                ref={register({
                  required: false,
                  pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
              <p></p>

              <input
                className="form-control"
                placeholder={t('nameform004.label')}
                type="text"
                size="58"
                name="cellulare"
                ref={register({ required: true, maxLength: 11, minLength: 8 })}
              />
              <p></p>

              <input
                className="form-control"
                placeholder={t('nameform005.label')}
                type="text"
                size="58"
                name="telefono"
                ref={register({ required: false, maxLength: 11, minLength: 8 })}
              />
              <p></p>

              <textarea
                className="form-control"
                rows="6"
                cols="58"
                placeholder={t('nameform006.label')}
                size="58"
                name="messaggio"
                ref={register}
              />

              <label>
              {t('nameform007.label')}
              </label>
              <input
                type="checkbox"
                name="informativa"
                value="Yes"
                ref={register({ required: true })}
              />

              <label>
              {t('nameform008.label')}
              </label>
              <input
                type="checkbox"
                name="consenso"
                value="Yes"
                ref={register({ required: true })}
              />
            </div>
            <div>
              <input className="btn btn-primary" type="submit" value={t('nameform009.label')} />
            </div>
          </div>
          <div className="col-lg-3" />
        </div>
      </div>
    </form>
  );
}
