import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import MainTemplate from "./components/mainLayout/template/mainTemplate";
import Home from "./components/home/home";
import Login from "./components/login/login";

import Chisiamo from "./components/home/chisiamo";
import CasSanGiuseppe from "./components/home/cas-sangiuseppe";
import TutSanGiuseppe from "./components/home/tut-sangiuseppe";
import RsaSanGiuseppe from "./components/home/rsa-sangiuseppe";
import VillaFerretti from "./components/home/villaferretti";

import SSPietroePaolo from "./components/home/sspietroepaolo";
import SSPietroePaoloG from "./components/home/sspietroepaolo-galleria";

import CuoreImmacolato from "./components/home/cuoreimmacolato";
import CuoreImmacolatoG from "./components/home/cuoreimmacolato-galleria";

import SanVincenzoDePaoli from "./components/home/sanvincenzodepaoli";
import SanVincenzoDePaoliG from "./components/home/sanvincenzodepaoli-galleria";

import MaterDei from "./components/home/materdei";
import VillaAurora from "./components/home/villaaurora";

import Missioni from "./components/home/missioni";
import Brasile from "./components/home/brasile";
import BrasileG from "./components/home/brasile-galleria";

import Ecuador from "./components/home/ecuador";
import EcuadorG from "./components/home/ecuador-galleria";

import Africa from "./components/home/africa";
import AfricaG from "./components/home/africa-galleria";

import Pollena from "./components/home/pollena";
import Portici from "./components/home/portici";
import Caiazzo from "./components/home/caiazzo";
import Treviso from "./components/home/treviso";

import Pastorale from "./components/home/pastorale";
import Scuola from "./components/home/scuola";
import ScuolaInfanziaSanGen from "./components/home/scuolainfanziasangen";
import ScuolaInfanziaIsola from "./components/home/scuolainfanziaisola";

import Dovesiamo from "./components/home/dovesiamo";
import Contatti from "./components/home/contatti";
import MailForm from "./components/home/mailform";

import Privacy from "./components/home/privacy";
import Xmille from "./components/home/xmille";

import SimpleReactLightbox from "simple-react-lightbox"; // Import Simple React Lightbox
import MyCookie from "./components/home/MyCookie";
import HttpsRedirect from "react-https-redirect";

const App = () => {
  return (
    <div>
      <HttpsRedirect>
        <SimpleReactLightbox>
          <Router basename={"/sito"}>
            <MainTemplate>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/chisiamo" component={Chisiamo} />

                <Route path="/cas-sangiuseppe" component={CasSanGiuseppe} />
                <Route path="/tut-sangiuseppe" component={TutSanGiuseppe} />
                <Route path="/rsa-sangiuseppe" component={RsaSanGiuseppe} />
                <Route path="/villaferretti" component={VillaFerretti} />

                <Route path="/sspietroepaolo" component={SSPietroePaolo} />
                <Route
                  path="/sspietroepaolo-galleria"
                  component={SSPietroePaoloG}
                />

                <Route path="/cuoreimmacolato" component={CuoreImmacolato} />
                <Route
                  path="/cuoreimmacolato-galleria"
                  component={CuoreImmacolatoG}
                />

                <Route
                  path="/sanvincenzodepaoli"
                  component={SanVincenzoDePaoli}
                />
                <Route
                  path="/sanvincenzodepaoli-galleria"
                  component={SanVincenzoDePaoliG}
                />
                <Route path="/materdei" component={MaterDei} />
                <Route path="/villaaurora" component={VillaAurora} />

                <Route path="/missioni" component={Missioni} />
                <Route path="/brasile" component={Brasile} />
                <Route path="/brasile-galleria" component={BrasileG} />

                <Route path="/ecuador" component={Ecuador} />
                <Route path="/ecuador-galleria" component={EcuadorG} />

                <Route path="/africa" component={Africa} />
                <Route path="/africa-galleria" component={AfricaG} />

                <Route path="/pollena" component={Pollena} />
                <Route path="/portici" component={Portici} />
                <Route path="/caiazzo" component={Caiazzo} />
                <Route path="/treviso" component={Treviso} />

                <Route path="/pastorale" component={Pastorale} />
                <Route path="/scuola" component={Scuola} />
                <Route
                  path="/scuolainfanziasangen"
                  component={ScuolaInfanziaSanGen}
                />
                <Route
                  path="/scuolainfanziaisola"
                  component={ScuolaInfanziaIsola}
                />
                <Route path="/dovesiamo" component={Dovesiamo} />
                <Route path="/contatti" component={Contatti} />
                <Route path="/mailform" component={MailForm} />

                <Route path="/privacy" component={Privacy} />
                <Route path="/xmille" component={Xmille} />

                <Route path="/login" component={Login} />
              </Switch>
            </MainTemplate>
          </Router>
          <MyCookie />
        </SimpleReactLightbox>
      </HttpsRedirect>
    </div>
  );
};

export default App;
