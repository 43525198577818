import React, { useState, useEffect } from "react";

import PdfDownloadLogo from "../../images/pdf-download-logo.png";
import Privacy from "../../images/privacy.jpg";

import Pdf1 from "../../documents/informativa-cookie.pdf";
import Pdf2 from "../../documents/INFORMATIVA-Privacy_Case.pdf";
import Pdf3 from "../../documents/CONSENSO-Privacy.pdf";
import Pdf4 from "../../documents/Reg_privacy_Case.pdf";
import Pdf5 from "../../documents/INFORMATIVA-Privacy_Scuole.pdf";
import Pdf6 from "../../documents/Reg_privacy_Scuole.pdf";
import Pdf7 from "../../documents/formazione_privacy.pdf";

import "../../css/home/home.css";
import { Cookies } from "react-cookie-consent";

import "../../i18n";
import { useTranslation } from "react-i18next";

const MyPrivacy = () => {
  const [stringa, setStringa] = useState("");

  useEffect(() => {
    // Update the document title using the browser API
    stringa === "ok" && Cookies.remove("accetta");

    stringa === "ok" && alert("Cookies cancellati");
  });

  const { t } = useTranslation();

  return (
    <>
      <div className="home-centro-h">
        <div className="row">
          <div className="col-lg-2" />

          <div className="col-lg-8 home-siamodasempre">
            <img className="img-responsive" src={Privacy} alt="privacy" />
            <h2>{t("privacy001.label")}</h2>
          </div>
          <div className="col-lg-4" />
        </div>
        <p></p>
        <div>
          <button onClick={() => setStringa("ok")}>
            {t("privacy002.label")}
          </button>
        </div>
        <p></p>
        <p></p>
        <hr width="700" size="2" color="#20b2aa" align="center" />
        <p></p>
        <div className="row home-siamodasempre">
          <div className="col-lg-4" />

          <div className="col-lg-1">
            <img
              height="50"
              width="50"
              className="book-passionate"
              src={PdfDownloadLogo}
              alt="team"
            />
          </div>
          <div className="col-lg-3">
            <h3>{t("msg013.label")}</h3>
          </div>
          <div className="col-lg-4" />
        </div>
        <p></p>
        <hr width="700" size="2" color="#20b2aa" align="center" />
        <p></p>
        <div className="row home-siamodasempre" align="left">
          <div className="col-3" />
          <div className="col-lg-7 interlinea-10">
            <ul type="square" align="left">
              <li>
                <a href={Pdf1}>
                  <h4>{t("privacy003.label")}</h4>
                </a>
              </li>
              <li>
                <a href={Pdf2}>
                  <h4>{t("privacy004.label")}</h4>
                </a>
              </li>
              <li>
                <a href={Pdf3}>
                  <h4>{t("privacy005.label")}</h4>
                </a>
              </li>
              <li>
                <a href={Pdf4}>
                  <h4>{t("privacy006.label")}</h4>
                </a>
              </li>
              <li>
                <a href={Pdf5}>
                  <h4>{t("privacy007.label")}</h4>
                </a>
              </li>
              <li>
                <a href={Pdf6}>
                  <h4>{t("privacy008.label")}</h4>
                </a>
              </li>
              <li>
                <a href={Pdf7}>
                  <h4>{t("privacy009.label")}</h4>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-2" />
        </div>
      </div>
    </>
  );
};

export default MyPrivacy;
