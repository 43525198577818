import React, { useEffect } from "react";
/*
import Immagine1 from "../../images/m-1.jpg";
import Immagine2 from "../../images/m-2.jpg";
import Immagine3 from "../../images/m-3.jpg";
import Immagine4 from "../../images/m-4.jpg";
import Immagine5 from "../../images/m-5-1.jpg";
import Immagine6 from "../../images/m-5-2.jpg";
import Immagine7 from "../../images/m-6.jpg";
import Immagine8 from "../../images/m-7.jpg";
*/
import PdfDownloadLogo from "../../images/pdf-download-logo.png";
import LogoIso from "../../images/logo-iso.jpg";

import Alanno1 from "../../photos/villaaurora/eAlanno-01.jpg";
import Foto01 from "../../photos/villaaurora/Foto-01.jpg";
import Foto02 from "../../photos/villaaurora/Foto-02.jpg";
import Foto03 from "../../photos/villaaurora/Foto-03.jpg";
import Foto04 from "../../photos/villaaurora/Foto-04.jpg";
import Foto05 from "../../photos/villaaurora/Foto-05.jpg";
import Foto06 from "../../photos/villaaurora/Foto-06.jpg";

import GalleriaLogo from "../../images/galleria.jpg";
/*
import Pdf1 from "../../documents/grotta-carta_servizi.pdf";
import Pdf2 from "../../documents/grotta-pai.pdf";
import Pdf3 from "../../documents/grotta-autosufficienza.pdf";
import Pdf4 from "../../documents/grotta-ingresso.pdf";
import Pdf5 from "../../documents/grotta-impegno.pdf";
import Pdf6 from "../../documents/grotta-organigramma.pdf";
import Pdf7 from "../../documents/grotta-brochure.pdf";
import PdfCovid from "../../documents/grotta-covid-ok.pdf";
import PdfProtCondiv from "../../documents/grotta-protocollo_condiviso.pdf";
import PdfSanitizzazione from "../../documents/grotta-sanitizzazione.pdf";
*/
import "../../css/home/home.css";
//import NameForm from "./nameform";
import ResponsiveImage from "./ResponsiveImage";
import LogoFacebook from "../../images/facebook.png";
import { Link } from "react-router-dom";

import '../../i18n';
import { useTranslation } from 'react-i18next';

const iframe =
  '<iframe height="500" style="width: 100%;" scrolling="no" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=via%20circonterranea%2010+(Titolo)&amp;ie=UTF8&amp;t=k&amp;z=16&amp;iwloc=B&amp;output=embed" frameborder="no" marginheight="0" marginwidth="0"</iframe>';

function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
    />
  );
}

const VillaAurora = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const { t } = useTranslation();

  return (
    <div className="home-centro-h">
      <div className="home-content">
        <div className="row">
          <div className="col-sm-8" align="center">
            <ResponsiveImage src={Alanno1} width={900} height={500} />
          </div>
          <div className="col-sm-4 home-siamodasempre">
            <hr width="150" size="1" color="#20b2aa" align="center" />

            <h1>{t('villaaurora001.label')}</h1>
            <h5>via Circonterranea 10 - 65020 Alanno (PE)</h5>
            <div className="row">
              <div className="col-1" />
              <div className="col-5">
                <h5>Tel. 085 8573181</h5>
              </div>
              <div className="col-2">
                <a
                  href="https://www.facebook.com/poverefiglie.dellavisitazione/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="facebook"
                    src={LogoFacebook}
                    className="logo-facebook"
                    width="30"
                    height="30"
                  />
                </a>
              </div>
              <div className="col-2">
                <Link className="link" to="/missioni">
                  <img
                    alt=""
                    src={GalleriaLogo}
                    width="30"
                    height="30"
                    title={t('msg026.label')}
                  />
                </Link>
              </div>
            </div>
            <h5>Email alanno@istitutosuorevisitazione.it</h5>

            <h2>{t('villaaurora002.label')}</h2>

            <p></p>
            <hr width="150" size="1" color="#20b2aa" align="center" />
            <p></p>
            <div className="row">
              <div className="col" />

              <div className="col">
                <img
                  height="30"
                  width="30"
                  className="book-passionate"
                  src={PdfDownloadLogo}
                  alt="team"
                />
              </div>
              <div className="col-auto">
                <h4>{t('msg013.label')}</h4>
              </div>
              <div className="col" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2" />

          <div className="col-lg-8">
            <h1 className="home-top20-c">{t('msg035.label')}</h1>

            <h5 className="home-top20-j">
            {t('villaaurora004.label')}
            </h5>

            <h1 className="home-top20-c">{t('msg003.label')}</h1>

            <Iframe iframe={iframe} />
          </div>
          <div className="col-lg-2" />
        </div>

        <div className="row">
          <div className="col-lg-2" />

          <div className="col-lg-8">
            <h1 className="home-top20-c">{t('villaaurora005.label')}</h1>
          </div>
          <div className="col-lg-2" />
        </div>
        <div className="row home-top20-verde">
          <div className="col-sm-1" />

          <div className="col-sm-10" align="center">
            <ResponsiveImage src={Foto01} width={1200} height={700} />
            <p></p>
            <ResponsiveImage src={Foto02} width={900} height={900} />
            <p></p>
            <ResponsiveImage src={Foto03} width={900} height={900} />
            <p></p>
            <ResponsiveImage src={Foto04} width={900} height={900} />
            <p></p>
            <ResponsiveImage src={Foto05} width={900} height={600} />
            <p></p>
            <ResponsiveImage src={Foto06} width={900} height={1000} />
            <p></p>
          </div>

          <div className="col-sm-1" />
        </div>

        <div className="row home-siamodasempre">
          <div className="col-sm-3" />

          <div className="col-sm-6">
            <h1 className="home-top20-c">{t('msg004.label')}</h1>
            <h5>
            {t('msg005.label')}
            </h5>
          </div>
          <div className="col-sm-3" />
        </div>
        <div className="row home-top20-c">
          <div className="col-sm-1" />

          <div className="col-sm-5">
            <ResponsiveImage src={LogoIso} width={520} height={380} />
          </div>
          <div className="col-sm-6 home-top20-j">
            <h1>{t('msg006.label')}</h1>
            <ul>
              <li>
                <i>{t('msg007.label')}</i>
              </li>
              <li>
                <i>{t('msg008.label')}</i>
              </li>
              <li>
                <i>{t('msg009.label')}</i>
              </li>
              <li>
                <i>{t('msg009c.label')}</i>
              </li>
              <li>
                <i>{t('msg010.label')}</i>
              </li>
              <li>
                <i>{t('msg011.label')}</i>
              </li>
              <li>
                <i>{t('msg012a.label')}</i>
              </li>
              
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VillaAurora;
