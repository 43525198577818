import React from "react";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    /*
    <div onChange={changeLanguage}>
      <input type="radio" value="ita" name="language" defaultChecked /> ITA
      <input type="radio" value="por" name="language" /> POR
      <input type="radio" value="esp" name="language" /> ESP
      <input type="radio" value="fra" name="language" /> FRA
      <input type="radio" value="eng" name="language" /> ENG
    </div>
    */
    <select className="nav-brand-text-12" id="lang" onChange={changeLanguage}>
      <option value="ita"> ITA</option>
      <option value="por"> POR</option>
      <option value="esp"> ESP</option>
      <option value="fra"> FRA</option>
      <option value="eng"> ENG</option>
    </select>
  );
};

export default LanguageSelector;
