import React from "react";
import Logo from "../../images/logo.gif";

import "../../css/home/home.css";
import NameForm from "./nameform";

import '../../i18n';
import { useTranslation } from 'react-i18next';

const Contatti = () => {

  const { t } = useTranslation();

  return (
    <div className="home-centro-h">
      <div>
        <img className="book-passionate" src={Logo} alt="team" />
      </div>

      <div className="row">
        <div className="col-lg-3" />

        <div className="col-lg-6 home-siamodasempre">
          <h2>{t('header000.label')}</h2>
        </div>
        <div className="col-lg-3" />
      </div>

      <NameForm />
    </div>
  );
};

export default Contatti;
