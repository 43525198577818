import React, { useEffect } from "react";
/*
import Immagine1 from "../../images/m-1.jpg";
import Immagine2 from "../../images/m-2.jpg";
import Immagine3 from "../../images/m-3.jpg";
import Immagine4 from "../../images/m-4.jpg";
import Immagine5 from "../../images/m-5-1.jpg";
import Immagine6 from "../../images/m-5-2.jpg";
import Immagine7 from "../../images/m-6.jpg";
import Immagine8 from "../../images/m-7.jpg";
*/
import PdfDownloadLogo from "../../images/pdf-download-logo.png";
import LogoIso from "../../images/logo-iso.jpg";

import Roma1 from "../../photos/sspietroepaolo/eRoma-01.jpg";
//import Roma2 from "../../images/roma-2.jpg";
//import Roma3 from "../../images/roma-3.jpg";
import Roma4 from "../../photos/sspietroepaolo/eRoma-02.jpg";
import Roma4a from "../../photos/sspietroepaolo/eRoma-03.jpg";

import Roma5 from "../../photos/sspietroepaolo/iRoma-01.jpg";
import Roma6 from "../../photos/sspietroepaolo/iRoma-07a.jpg";
import Roma7 from "../../photos/sspietroepaolo/iRoma-07b.jpg";
import Roma8 from "../../photos/sspietroepaolo/iRoma-07.jpg";
import Roma9 from "../../photos/sspietroepaolo/iRoma-07c.jpg";

import Roma13 from "../../photos/sspietroepaolo/iRoma-08.jpg";
//import Roma14 from "../../photos/sspietroepaolo/iRoma-09.jpg";
//import Roma15 from "../../photos/sspietroepaolo/iRoma-10.jpg";
import Roma16 from "../../photos/sspietroepaolo/iRoma-11.jpg";
//import Roma17 from "../../photos/sspietroepaolo/iRoma-12.jpg";
import Roma18 from "../../photos/sspietroepaolo/iRoma-16.jpg";
import Roma19 from "../../photos/sspietroepaolo/iRoma-15.jpg";
import Roma20 from "../../photos/sspietroepaolo/iRoma-03a.jpg";
import Roma21 from "../../photos/sspietroepaolo/iRoma-04a.jpg";
import Roma22 from "../../photos/sspietroepaolo/iRoma-04b.jpg";
import Roma23 from "../../photos/sspietroepaolo/iRoma-04c.jpg";

import GalleriaLogo from "../../images/galleria.jpg";

import Pdf1 from "../../documents/roma-carta_servizi.pdf";
import Pdf2 from "../../documents/roma-pai.pdf";
import Pdf3 from "../../documents/roma-autosufficienza.pdf";
import Pdf4 from "../../documents/roma-ingresso.pdf";
import Pdf5 from "../../documents/roma-impegno.pdf";
import Pdf6 from "../../documents/roma-organigramma.pdf";
import Pdf7 from "../../documents/roma-brochure.pdf";
import PdfCovid from "../../documents/roma-covid.pdf";
import PdfProtCondiv from "../../documents/roma-protocollo_condiviso.pdf";
import PdfSanitizzazione from "../../documents/roma-sanitizzazione.pdf";
import LogoFacebook from "../../images/facebook.png";

import "../../css/home/home.css";
//import NameForm from "./nameform";
import ResponsiveImage from "./ResponsiveImage";
import { Link } from "react-router-dom";

import '../../i18n';
import { useTranslation } from 'react-i18next';

const iframe =
  '<iframe height="500" style="width: 100%;" scrolling="no" src="https://maps.google.com/maps?width=700&amp;height=450&amp;hl=en&amp;q=via%20della%20magliana%20173%20roma+(Titolo)&amp;ie=UTF8&amp;t=k&amp;z=16&amp;iwloc=B&amp;output=embed" frameborder="no" marginheight="0" marginwidth="0"</iframe>';

function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
    />
  );
}

const SSPietroepaolo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const { t } = useTranslation();

  return (
    <div className="home-centro-h">
      <div className="home-content">
        <div className="row">
          <div className="col-sm-8" align="center">
            <ResponsiveImage src={Roma1} width={900} height={600} />
          </div>
          <div className="col-sm-4 home-siamodasempre">
            <hr width="150" size="1" color="#20b2aa" align="center" />

            <h1>{t('sspietroepaolo001.label')}</h1>
            <h5>{t('sspietroepaolo002.label')}</h5>

            <div className="row">
              <div className="col-1" />
              <div className="col-5">
                <h5>Tel. 06 555262709</h5>
              </div>
              <div className="col-2">
                <a
                  href="https://www.facebook.com/poverefiglie.dellavisitazione/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="facebook"
                    src={LogoFacebook}
                    className="logo-facebook"
                    width="30"
                    height="30"
                  />
                </a>
              </div>
              <div className="col-2">
                <Link className="link" to="/sspietroepaolo-galleria">
                  <img
                    alt=""
                    src={GalleriaLogo}
                    width="30"
                    height="30"
                    title={t('msg026.label')}
                  />
                </Link>
              </div>
            </div>
            <h5>Email roma@istitutosuorevisitazione.it</h5>

            <h2>{t('sspietroepaolo003.label')}</h2>

            <p></p>
            <hr width="150" size="1" color="#20b2aa" align="center" />
            <p></p>
            <div className="row">
              <div className="col" />

              <div className="col">
                <img
                  height="30"
                  width="30"
                  className="book-passionate"
                  src={PdfDownloadLogo}
                  alt="team"
                />
              </div>
              <div className="col-auto">
                <h4>{t('msg013.label')}</h4>
              </div>
              <div className="col" />
            </div>
            <div className="row" align="left">
              <div className="col-2" />
              <div className="col-auto interlinea-10">
                <ul type="square">
                  <li>
                    <a href={Pdf1}>
                      <p>{t('msg014.label')}</p>
                    </a>
                  </li>
                  <li>
                    <a href={Pdf2}>
                      <p>{t('msg015.label')}</p>
                    </a>
                  </li>
                  <li>
                    <a href={Pdf3}>
                      <p>{t('msg016.label')}</p>
                    </a>
                  </li>
                  <li>
                    <a href={Pdf4}>
                      <p>{t('msg017.label')}</p>
                    </a>
                  </li>
                  <li>
                    <a href={Pdf5}>
                      <p>{t('msg018.label')}</p>
                    </a>
                  </li>
                  <li>
                    <a href={Pdf6}>
                      <p>{t('msg019.label')}</p>
                    </a>
                  </li>
                  <li>
                    <a href={Pdf7}>
                      <p>{t('msg020.label')}</p>
                    </a>
                  </li>
                  <li>
                    <a href={PdfCovid}>
                      <p>{t('msg021.label')}</p>
                    </a>
                  </li>
                  <li>
                    <a href={PdfProtCondiv}>
                      <p>{t('msg022.label')}</p>
                    </a>
                  </li>
                  <li>
                    <a href={PdfSanitizzazione}>
                      <p>{t('msg023.label')}</p>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col" />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-2" />

          <div className="col-lg-8">
            <h1 className="home-top20-c">{t('sspietroepaolo004.label')}</h1>
            <h5 className="home-top20-j">
            {t('sspietroepaolo005.label')}
            </h5>
            <h1 className="home-top20-c">{t('msg003.label')}</h1>

            <Iframe iframe={iframe} />
          </div>
          <div className="col-lg-2" />
        </div>

        <div className="row home-top20-verde">
          <div className="col-sm-1" />

          <div className="col-sm-10">
            <font color="white">
              <h1>{t('sspietroepaolo006.label')}</h1>
              <h5>
              {t('sspietroepaolo007.label')}
              </h5>
            </font>
            <div className="row" align="center">
              <div className="col">
                <ResponsiveImage src={Roma4} width={650} height={500} />
              </div>
              <div className="col">
                <ResponsiveImage src={Roma4a} width={450} height={500} />
              </div>
            </div>
            <p></p>
          </div>

          <div className="col-sm-1" />
          <p></p>
        </div>
        <div className="row home-top20-verde">
          <div className="col-sm-1" />

          <div className="col-sm-10">
            <font color="white">
              <h1>{t('sspietroepaolo008.label')}</h1>
              <h5>
              {t('sspietroepaolo009.label')}
              </h5>
            </font>
            <ResponsiveImage src={Roma5} width={1500} height={1100} />
            <p></p>
          </div>

          <div className="col-sm-1" />
          <p></p>
        </div>

        <div className="row">
          <div className="col-lg-2" />

          <div className="col-lg-8">
            <h1 className="home-top20-c">{t('msg028.label')}</h1>
          </div>
          <div className="col-lg-2" />
        </div>

        <div className="row home-top20-verde">
          <div className="col-sm-1" />

          <div className="col-sm-10">
            <font color="white">
              <h1>{t('msg029.label')}</h1>
              <h5>{t('sspietroepaolo010.label')}</h5>
            </font>
            <div className="row">
              <div className="col">
                <ResponsiveImage src={Roma6} width={550} height={450} />
              </div>
              <div className="col">
                <ResponsiveImage src={Roma8} width={550} height={450} />
              </div>
            </div>
            <p></p>
            <div className="row">
              <div className="col">
                <ResponsiveImage src={Roma7} width={550} height={450} />
              </div>
              <div className="col">
                <ResponsiveImage src={Roma9} width={550} height={450} />
              </div>
            </div>
            <p></p>
          </div>
          <p></p>
          <div className="col-sm-1" />
        </div>

        <div className="row home-top20-verde">
          <div className="col-sm-1" />

          <div className="col-sm-10">
            <font color="white">
              <h1>{t('msg033.label')}</h1>
              <h5>{t('msg034.label')}</h5>
            </font>
            <div className="row">
              <div className="col">
                <ResponsiveImage src={Roma18} width={550} height={430} />
              </div>
              <div className="col">
                <ResponsiveImage src={Roma19} width={550} height={430} />
              </div>
            </div>
            <p></p>
          </div>

          <div className="col-sm-1" />
        </div>

        <div className="row home-top20-verde">
          <div className="col-sm-1" />

          <div className="col-sm-10">
            <font color="white">
              <h1>{t('msg030.label')}</h1>
              <h5>
              {t('sspietroepaolo011.label')}
              </h5>
            </font>
            <div className="row">
              <div className="col">
                <ResponsiveImage src={Roma20} width={550} height={430} />
              </div>
              <div className="col">
                <ResponsiveImage src={Roma21} width={550} height={430} />
              </div>
            </div>
            <p></p>
            <div className="row">
              <div className="col">
                <ResponsiveImage src={Roma22} width={550} height={430} />
              </div>
              <div className="col">
                <ResponsiveImage src={Roma23} width={550} height={430} />
              </div>
            </div>
            <p></p>
          </div>

          <div className="col-sm-1" />
        </div>

        <div className="row home-top20-verde">
          <div className="col-sm-1" />

          <div className="col-sm-10">
            <font color="white">
              <h1>{t('msg031.label')}</h1>
              <h5>{t('msg032.label')}</h5>
            </font>
            <div className="row">
              <div className="col">
                <ResponsiveImage src={Roma13} width={550} height={430} />
              </div>
              <div className="col">
                <ResponsiveImage src={Roma16} width={550} height={430} />
              </div>
            </div>
            <p></p>

            <p></p>
          </div>
          <div className="col-sm-1" />
        </div>
        <p></p>
        <div className="row home-siamodasempre">
          <div className="col-sm-3" />

          <div className="col-sm-6">
            <h1 className="home-top20-c">{t('msg004.label')}</h1>
            <h5>
            {t('msg005.label')}
            </h5>
          </div>
          <div className="col-sm-3" />
        </div>

        <div className="row home-top20-c">
          <div className="col-sm-1" />

          <div className="col-sm-5">
            <ResponsiveImage src={LogoIso} width={520} height={380} />
          </div>
          <div className="col-sm-6 home-top20-j">
            <h1>{t('msg006.label')}</h1>
            <ul>
              <li>
                <i>{t('msg007.label')}</i>
              </li>
              <li>
                <i>
                {t('msg008.label')}
                </i>
              </li>
              <li>
                <i>{t('msg009a.label')}</i>
              </li>
              <li>
                <i>
                {t('msg009b.label')}
                </i>
              </li>
              <li>
                <i>{t('msg010.label')}</i>
              </li>
              <li>
                <i>{t('msg011.label')}</i>
              </li>
              <li>
                <i>
                {t('msg012a.label')}
                </i>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SSPietroepaolo;
