import React, { useEffect } from "react";

import PdfDownloadLogo from "../../images/pdf-download-logo.png";

import Foto1 from "../../photos/ecuador/Foto-01.jpg";
import Foto2 from "../../photos/ecuador/Foto-02.jpg";
import Foto3 from "../../photos/ecuador/Foto-03.jpg";
import Foto4 from "../../photos/ecuador/Foto-04.jpg";
import Foto5 from "../../photos/ecuador/Foto-05.jpg";
/*
import Foto6 from "../../photos/ecuador/Foto-06.jpg";
import Foto7 from "../../photos/ecuador/Foto-07.jpg";
import Foto8 from "../../photos/ecuador/Foto-08.jpg";
import Foto9 from "../../photos/ecuador/Foto-09.jpg";
import Foto10 from "../../photos/ecuador/Foto-10.jpg";
import Foto11 from "../../photos/ecuador/Foto-11.jpg";
import Foto12 from "../../photos/ecuador/Foto-12.jpg";
import Foto13 from "../../photos/ecuador/Foto-13.jpg";
import Foto14 from "../../photos/ecuador/Foto-14.jpg";
import Foto15 from "../../photos/ecuador/Foto-15.jpg";
import Foto16 from "../../photos/ecuador/Foto-16.jpg";
import Foto17 from "../../photos/ecuador/Foto-17.jpg";
import Foto18 from "../../photos/ecuador/Foto-18.jpg";
import Foto19 from "../../photos/ecuador/Foto-19.jpg";
import Foto20 from "../../photos/ecuador/Foto-20.jpg";
import Foto21 from "../../photos/ecuador/Foto-21.jpg";
import Foto22 from "../../photos/ecuador/Foto-22.jpg";
import Foto23 from "../../photos/ecuador/Foto-23.jpg";
*/
import GalleriaLogo from "../../images/galleria.jpg";
/*
import Pdf1 from "../../documents/grotta-carta_servizi.pdf";
import Pdf2 from "../../documents/grotta-pai.pdf";
import Pdf3 from "../../documents/grotta-autosufficienza.pdf";
*/
import "../../css/home/home.css";
//import NameForm from "./nameform";
import ResponsiveImage from "./ResponsiveImage";
import LogoFacebook from "../../images/facebook.png";
import { Link } from "react-router-dom";

import '../../i18n';
import { useTranslation } from 'react-i18next';

import "../../css/home/video-react.css";
import { Player } from 'video-react';
import Video from "../../videos/ecuador.mp4";

const iframe =
  '<iframe height="500" style="width: 100%;" scrolling="no" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=BAHIA%20DE%20CARAQUEZ%20(MANAB%C3%8C)+(Titolo)&amp;ie=UTF8&amp;t=k&amp;z=12&amp;iwloc=B&amp;output=embed" frameborder="no" marginheight="0" marginwidth="0"</iframe>';

function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
    />
  );
}

const Ecuador = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const { t } = useTranslation();

  return (
    <div className="home-centro-h">
      <div className="home-content">
        <div className="row">
          <div className="col-sm-8 home-spazio-20" align="center">
            {/*<ResponsiveImage src={Foto1} width={900} height={600} />*/}
            <Player
              playsInline
              poster={Foto1}
              src={Video}
            />
          </div>
          <div className="col-sm-3 home-siamodasempre">
            <hr width="150" size="1" color="#20b2aa" align="center" />

            <h1>{t('ecuador001.label')}</h1>
            <h5>Bahía de Caráquez (Manabí)</h5>
            <h5>San Vicente (Manabí)</h5>

            <div className="row">
              <div className="col-1" />
              <div className="col-3"></div>
              <div className="col-2">
                <a
                  href="https://www.facebook.com/poverefiglie.dellavisitazione/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="facebook"
                    src={LogoFacebook}
                    className="logo-facebook"
                    width="30"
                    height="30"
                  />
                </a>
              </div>
              <div className="col-2">
                <Link className="link" to="/ecuador-galleria">
                  <img
                    alt=""
                    src={GalleriaLogo}
                    width="30"
                    height="30"
                    title={t('msg026.label')}
                  />
                </Link>
              </div>
            </div>
            <h5>Email ecuador@istitutosuorevisitazione.it</h5>

            <h2>{t('ecuador002.label')}</h2>

            <p></p>
            <hr width="150" size="1" color="#20b2aa" align="center" />
            <p></p>
            <div className="row">
              <div className="col" />

              <div className="col">
                <img
                  height="30"
                  width="30"
                  className="book-passionate"
                  src={PdfDownloadLogo}
                  alt="team"
                />
              </div>
              <div className="col-auto">
                <h4>{t('msg013.label')}</h4>
              </div>
              <div className="col" />
            </div>
            <div className="row" align="left">
              <div className="col-2" />

              <div className="col" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2" />

          <div className="col-lg-8">
            <h1 className="home-top20-c">{t('msg035.label')}</h1>

            <h5 className="home-top20-j">
            {t('ecuador003.label')}
            </h5>

            <h1 className="home-top20-c">{t('msg003.label')}</h1>

            <Iframe iframe={iframe} />
          </div>
          <div className="col-lg-2" />
        </div>

        <div className="row">
          <div className="col-lg-2" />

          <div className="col-lg-8">
            <h1 className="home-top20-c">{t('msg036.label')}</h1>
          </div>
          <div className="col-lg-2" />
        </div>
        <div className="row home-top20-verde">
          <div className="col-sm-1" />

          <div className="col-sm-10">
            <font color="white">
              <h1>{t('ecuador004.label')}</h1>
            </font>
            <div className="row home-top20-verde">
              <div className="col">
                <ResponsiveImage src={Foto2} width={550} height={350} />
              </div>
              <div className="col">
                <font color="white" align="justify">
                  <h5>
                  {t('ecuador005.label')}
                  </h5>
                  <h5>
                  {t('ecuador006.label')}
                  </h5>
                  <h5>
                  {t('ecuador007.label')}
                  </h5>
                </font>
              </div>
            </div>
            <p></p>
            <font color="white">
              <h3>{t('ecuador008.label')}</h3>
            </font>
            <ResponsiveImage src={Foto3} width={1200} height={900} />
            <p></p>
            <ResponsiveImage src={Foto4} width={1200} height={800} />
            <p></p>
            <ResponsiveImage src={Foto5} width={1200} height={900} />
            <p></p>
          </div>

          <div className="col-sm-1" />
        </div>
      </div>
    </div>
  );
};

export default Ecuador;
