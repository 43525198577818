import React from "react";
import "../../css/home/home.css";
import ResponsiveImage from "./ResponsiveImage";
import LogoFacebook from "../../images/facebook.png";
import CasaMadre from "../../images/CasaMadre.png";
import Congregazione1 from "../../images/congregazione-1.jpg";
import Congregazione2 from "../../images/congregazione-2.jpg";
import Congregazione3 from "../../images/congregazione-3.jpg";

import '../../i18n';
import { useTranslation } from 'react-i18next';

const iframe =
  '<iframe height="500" style="width: 100%;" scrolling="no" src="https://maps.google.com/maps?width=700&amp;height=500&amp;hl=en&amp;q=corso%20bruno%20buozzi%20174%20napoli+(Titolo)&amp;ie=UTF8&amp;t=k&amp;z=17&amp;iwloc=B&amp;output=embed" frameborder="no" marginheight="0" marginwidth="0"</iframe>';

function Iframe(props) {

  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
    />
  );
}

const Chisiamo = () => {
  const { t } = useTranslation();

  return (
    <div className="home-centro-h">
      <div className="home-content">
        <div className="row">
          <div className="col-sm-4 home-top20-c">
            <hr width="150" size="1" color="#20b2aa" align="center" />

            <h1>{t('chisiamo001.label')}</h1>
            <h5>{t('chisiamo002.label')}</h5>
            <div className="row">
              <div className="col-1" />

              <div className="col-6">
                <h5>Tel. 081 5729117</h5>
              </div>
              <div className="col-1">
                <a
                  href="https://www.facebook.com/poverefiglie.dellavisitazione/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="facebook"
                    src={LogoFacebook}
                    className="logo-facebook"
                    width="30"
                    height="30"
                  />
                </a>
              </div>
            </div>
            <h5>Email casamadre@istitutosuorevisitazione.it</h5>

            <h2>{t('chisiamo003.label')}</h2>

            <p></p>
            <hr width="150" size="1" color="#20b2aa" align="center" />
            <p></p>
          </div>
          <div className="col-sm-8">
            <ResponsiveImage src={CasaMadre} width={750} height={500} />
          </div>
        </div>

        <div className="row home-top20-c">
          <div className="col-lg-4 home-top40">
            <div className="row">
              <div className="col home-top40">
                <ResponsiveImage
                  src={Congregazione1}
                  width={400}
                  height={400}
                />
              </div>

              <div className="col home-top40">
                <ResponsiveImage
                  src={Congregazione2}
                  width={400}
                  height={400}
                />
              </div>
              <div className="col home-top40">
                <ResponsiveImage
                  src={Congregazione3}
                  width={400}
                  height={550}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <h1 className="home-top20-c">{t('chisiamo004.label')}</h1>
            <h5 className="home-top20-j">
            {t('chisiamo005.label')}
            </h5>

            <h5 className="home-top20-j">
              <i>
              {t('chisiamo006.label')}
              </i>
            </h5>
            <h5 className="home-top20-j">
            {t('chisiamo007.label')}
            </h5>

            <h1 className="home-top20-c">
            {t('chisiamo008.label')}
            </h1>

            <h5 className="home-top20-j">
            {t('chisiamo009.label')}
            </h5>

            <h1 className="home-top20-c">{t('msg003.label')}</h1>

            <Iframe iframe={iframe} />
          </div>
          <div className="col-lg-1" />
        </div>
      </div>
    </div>
  );
};

export default Chisiamo;
