import React, { useState, useEffect } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";

const MyCookie = () => {
  //console.log("entro in MyCookie: " + Cookies.get("accetta"));
  const [stringa, setStringa] = useState("");

  useEffect(() => {
    // Update the document title using the browser API
    stringa === "ok" && Cookies.remove("accetta");
  });

  return (
    <CookieConsent
      cookieName="accetta"
      location="bottom"
      //debug={true}
      //location="top"
      //expires={1}
      style={{ fontSize: "12px" }}
      buttonStyle={{
        background: "green",
        color: "white",
        fontWeight: "bolder",
        textShadow: "2px 2px black",
      }}
      declineButtonStyle={{
        background: "white",
        color: "grey",
        fontWeight: "bolder",
      }}
      enableDeclineButton
      declineButtonText="Rifiuto"
      onDecline={() => {
        setStringa("ok");
      }}
      //acceptOnScroll={true}
      buttonText="Accetto"
      /*
          onAccept={({ acceptedByScrolling }) => {
            if (acceptedByScrolling) {
              // triggered if user scrolls past threshold
              alert("Accept was triggered by user scrolling");
            } else {
              alert("Accept was triggered by clicking the Accept button");
            }
          }}
          
      onAccept={() => {
        alert(" consenso dato ");
      }}
      */
    >
      Questo sito utilizza i cookie per migliorare servizi ed esperienza degli
      utenti. Se decidi di continuare la navigazione consideriamo che accetti il
      loro uso. Per saperne di più clicca su informativa cookie. L'informativa
      sul trattamento dei dati personali è disponibile in privacy.
      <span style={{ fontSize: "10px" }}></span>
    </CookieConsent>
  );
};

export default MyCookie;
