import React from "react";

import "../../../css/mainLayout/footer/footer.css";
import LogoNewsLetter from "../../../images/newsletter.png";

import LogoFacebook from "../../../images/facebook.png";
//import LogoGooglePlus from "../../../images/google-plus.png";
//import LogoTwitter from "../../../images/twitter.png";
//import { Link } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import PdfCookie from "../../../documents/informativa-cookie.pdf";
import PdfInfCase from "../../../documents/INFORMATIVA-Privacy_Case.pdf";
import PdfInfScuole from "../../../documents/INFORMATIVA-Privacy_Scuole.pdf";
import PdfGiornalino from "../../../documents/GiornalinoAprile2020.pdf";
import PdfBarraNova from "../../../documents/BarraNova.pdf";
import PdfFeiraSantana from "../../../documents/FeiraSantana.pdf";
import PdfCBN from "../../../documents/IlProgettoCentroBarraNova.pdf";
import PdfCaiazzo from "../../../documents/CaiazzoSolidarieta.pdf";
import PdfMadreClaudiaRusso from "../../../documents/MadreClaudiaRusso.pdf";
import PdfNews01 from "../../../documents/News001.pdf";
import PdfNews02 from "../../../documents/News002.pdf";

import '../../../i18n';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footer">
      <Navbar collapseOnSelect expand="lg" className="my-bg-success">
        <span className="nav-brand-footer-text">
        {t('footer000.label')} &nbsp; &nbsp;
        </span>

        <a
          href={t('msg002.label')}
          
          target="_blank"
          rel="noopener noreferrer"
         
        >
          <img alt="" src={LogoNewsLetter} width="50" height="35" />
        </a>

        <a
          className="nav navbar-nav navbar-right ml-auto"
          href="https://www.facebook.com/poverefiglie.dellavisitazione/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            alt="facebook"
            src={LogoFacebook}
            className="logo-facebook"
            width="40"
            height="40"
          />
        </a>
      </Navbar>
      <div className="container-fluid">
        <div className="row fondo-grigio">
          <div className="col-4">
            <div className="row">
              <div className="col home-siamodasempre-j-left">
                <h6>{t('footer100.label')}</h6>
                <div className="row">
                  <div className="col">
                    <div className="link home-fs">
                      <a href={PdfMadreClaudiaRusso}>{t('footer101.label')}</a>
                    </div>
                    <div>
                      <div className="link home-fs">
                        <a href={PdfBarraNova}>{t('footer102.label')}</a>
                      </div>
                    </div>
                    <div>
                      <div className="link home-fs">
                        <a href={PdfFeiraSantana}>{t('footer103.label')}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col home-siamodasempre-j">
                <h6>{t('footer200.label')}</h6>
                <div className="row">
                  <div className="col">
                    <div>
                      <div className="link home-fs">
                        <a href={PdfCBN}>{t('footer201.label')}</a>
                      </div>
                    </div>
                    <div>
                      <a
                        className="nav navbar-nav navbar-right ml-auto home-fs"
                        href="http://www.concorsiletterari.it/concorso,6784,Premio%20Nazionale%20di%20Poesia%20%22Madre%20Claudia%20Russo%22"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('footer202.label')}
                      </a>
                    </div>
                    <div>
                      <div className="link home-fs">
                        <a href={PdfCaiazzo}>
                        {t('footer203.label')}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="row">
              <div className="col home-siamodasempre-j">
                <h6>{t('footer300.label')}</h6>
                <div className="row">
                  <div className="col">
                    <div>
                      <a
                        className="nav navbar-nav navbar-right ml-auto home-fs"
                        href="http://www.scuoledellavisitazione.it/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('footer301.label')}
                      </a>
                    </div>
                    <div>
                      <a
                        className="nav navbar-nav navbar-right ml-auto home-fs"
                        href="https://www.icmcrusso-solimena.edu.it/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('footer302.label')}
                      </a>
                    </div>
                    <div>
                      <a
                        className="nav navbar-nav navbar-right ml-auto home-fs"
                        href="https://concuoredimadre.blogspot.com/2019/03/grottaferrata-missione-camilliana-una.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('footer303.label')}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col home-siamodasempre-j">
                <h6>{t('footer400.label')}</h6>
                <div className="row">
                  <div className="col">
                    <div>
                      <div className="link home-fs">
                        <a href={PdfNews01}>{t('footer401.label')}</a>
                      </div>
                    </div>
                    <div className="link home-fs">
                      <a href={PdfNews02}>{t('footer402.label')}</a>
                    </div>
                    <div>
                      <a
                        className="nav navbar-nav navbar-right ml-auto home-fs"
                        href="http://www.istitutosuorevisitazione.it/oldsite"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('footer403.label')}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="row">
              <div className="col home-siamodasempre-j">
                <h6>{t('footer500.label')}</h6>
                <div className="row">
                  <div className="col">
                    <div className="link home-fs">
                      <a href={PdfGiornalino}>{t('footer501.label')}</a>
                    </div>
                    <div>
                      <a
                        className="nav navbar-nav navbar-right ml-auto home-fs"
                        href="https://www.famigliacristiana.it/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('footer502.label')}
                      </a>
                    </div>
                    <div>
                      <a
                        className="nav navbar-nav navbar-right ml-auto home-fs"
                        href="https://www.avvenire.it/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('footer503.label')}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col home-siamodasempre-j">
                <h6>{t('footer600.label')}</h6>
                <div className="row">
                  <div className="col">
                    <div className="link home-fs">
                      <a href={PdfCookie}>{t('footer601.label')}</a>
                    </div>

                    <div className="link home-fs">
                      <a href={PdfInfCase}>{t('footer602.label')}</a>
                    </div>
                    <div className="link home-fs">
                      <a href={PdfInfScuole}>{t('footer603.label')}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
