import React from "react";
import Carosello from "./carosello";
import HomeImage from "../../images/homeimage.jpg";
import HomeImage1 from "../../images/homeimage1.jpg";
import HomeImage2 from "../../images/homeimage2.jpg";
import HomeImage3 from "../../images/homeimage3.jpg";
import HomeImage4 from "../../images/homeimage4.jpg";

import "../../css/home/home.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ResponsiveImage from "./ResponsiveImage";

import "../../i18n";
import { useTranslation } from "react-i18next";

//import AuguriNatale2020 from "../../images/AuguriNatale2020.jpg";

import "../../css/home/video-react.css";
import { Player } from "video-react";
import Video from "../../videos/istituto.mp4";
import MadreClaudia from "../../images/MadreClaudia.jpg";
import MadreClaudiaTesto from "../../images/MadreClaudiaTesto.jpg";
import Istituto5x1000 from "../../images/Istituto5x1000.jpg";
import Blink from "react-blink-text";

/*  istruzioni da utilizzare per video su youtube
const iframe =
  '<iframe width="373" height="280" src="https://www.youtube.com/embed/gJXsxuP4ORg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
    />
  );
}
*/
const Home = () => {
  const { t } = useTranslation();

  return (
    <div className="home-content">
      <div className="row">
        {/*<div className="col-sm-8" align="center">  da ripristinare dopo il periodo natalizio */}
        <div className="col-sm-5" align="center">
          {/*<ResponsiveImage src={HomeImage} width={900} height={500} /> da ripristinare dopo il periodo natalizio */}
          <ResponsiveImage src={HomeImage} width={515} height={300} />
          <p></p>
          <div
            style={{
              backgroundColor: "#00ffff",
              borderRadius: 10,
            }}
          >
            <h1>Dona il tuo 5x1000</h1>
            <Blink color="blue" text="C.F. 80011870633"></Blink>
          </div>
        </div>

        {/* questo div va eliminato dopo il periodo natalizio*/}
        <div className="col-sm-3" align="center">
          {/*<ResponsiveImage src={AuguriNatale2020} width={450} height={550} />*/}
          {/*<Iframe iframe={iframe} />    istruzione per video su youtube*/}

          <ResponsiveImage src={Istituto5x1000} width={300} height={500} />
        </div>

        <div className="col-sm-4 home-siamodasempre">
          <div className="row">
            <div className="col-sm" align="center">
              <Player poster={MadreClaudia} src={Video} />
            </div>
            <div className="col-sm" align="center">
              <ResponsiveImage
                src={MadreClaudiaTesto}
                width={300}
                height={100}
              />
            </div>
          </div>
          <h1> {t("home001.label")} </h1>
          <h6>{t("home002.label")}</h6>
          <h6>{t("home003.label")}</h6>
          <h6>{t("home004.label")}</h6>
        </div>
      </div>
      <p></p>

      <div className="row home-siamodasempre">
        <div className="col-sm-3" align="center">
          <ResponsiveImage src={HomeImage1} width={300} height={180} />
          <h4>{t("home100.label")}</h4>
          <h6>
            <i>{t("home101.label")}</i>({t("home102.label")})
          </h6>
        </div>
        <div className="col-sm-3" align="center">
          <ResponsiveImage src={HomeImage2} width={300} height={180} />
          <h4>{t("home200.label")}</h4>
          <h6>
            <i>{t("home201.label")}</i>({t("home202.label")})
          </h6>
        </div>
        <div className="col-sm-3" align="center">
          <ResponsiveImage src={HomeImage3} width={300} height={180} />
          <h4>{t("home300.label")}</h4>
          <h6>
            <i>{t("home301.label")}</i>({t("home302.label")})
          </h6>
        </div>
        <div className="col-sm-3" align="center">
          <ResponsiveImage src={HomeImage4} width={300} height={180} />
          <h4>{t("home400.label")}</h4>
          <h6>
            <i>{t("home401.label")}</i>({t("home402.label")})
          </h6>
        </div>

        <p></p>
      </div>
      <p></p>
      <div className="row">
        <div className="col-2" />
        <div className="col-8">
          <Carosello />
        </div>
        <div className="col-2" />
      </div>
    </div>
  );
};

export default Home;
