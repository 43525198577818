import React, { useEffect } from "react";
/*
import Immagine1 from "../../images/m-1.jpg";
import Immagine2 from "../../images/m-2.jpg";
import Immagine3 from "../../images/m-3.jpg";
import Immagine4 from "../../images/m-4.jpg";
import Immagine5 from "../../images/m-5-1.jpg";
import Immagine6 from "../../images/m-5-2.jpg";
import Immagine7 from "../../images/m-6.jpg";
import Immagine8 from "../../images/m-7.jpg";
*/
import PdfDownloadLogo from "../../images/pdf-download-logo.png";
import LogoIso from "../../images/logo-iso.jpg";

import Grotta1 from "../../photos/cuoreimmacolato/eGrotta-01.jpg";
import Grotta2 from "../../photos/cuoreimmacolato/iGrotta-30.jpg";
import Grotta3 from "../../photos/cuoreimmacolato/iGrotta-31.jpg";
import Grotta4 from "../../photos/cuoreimmacolato/iGrotta-19.jpg";
import Grotta5 from "../../photos/cuoreimmacolato/iGrotta-26.jpg";
import Grotta6 from "../../photos/cuoreimmacolato/iGrotta-27.jpg";
import Grotta7 from "../../photos/cuoreimmacolato/iGrotta-24.jpg";
import Grotta8 from "../../photos/cuoreimmacolato/iGrotta-29.jpg";
import Grotta9 from "../../photos/cuoreimmacolato/iGrotta-28.jpg";
import Grotta10 from "../../photos/cuoreimmacolato/iGrotta-14.jpg";
//import Grotta11 from "../../photos/cuoreimmacolato/iGrotta-22.jpg";
//import Grotta12 from "../../photos/cuoreimmacolato/iGrotta-23.jpg";
import Grotta13 from "../../photos/cuoreimmacolato/iGrotta-21.jpg";
import Grotta14 from "../../photos/cuoreimmacolato/iGrotta-25.jpg";
import Grotta18 from "../../photos/cuoreimmacolato/iGrotta-32.jpg";
import Grotta19 from "../../photos/cuoreimmacolato/iGrotta-33.jpg";
import Grotta24 from "../../photos/cuoreimmacolato/eGrotta-07.jpg";
import Grotta25 from "../../photos/cuoreimmacolato/eGrotta-01.jpg";
import Grotta26 from "../../photos/cuoreimmacolato/eGrotta-08.jpg";
import GalleriaLogo from "../../images/galleria.jpg";

import Pdf1 from "../../documents/grotta-carta_servizi.pdf";
import Pdf2 from "../../documents/grotta-pai.pdf";
import Pdf3 from "../../documents/grotta-autosufficienza.pdf";
import Pdf4 from "../../documents/grotta-ingresso.pdf";
import Pdf5 from "../../documents/grotta-impegno.pdf";
import Pdf6 from "../../documents/grotta-organigramma.pdf";
import Pdf7 from "../../documents/grotta-brochure.pdf";
import PdfCovid from "../../documents/grotta-covid-ok.pdf";
import PdfProtCondiv from "../../documents/grotta-protocollo_condiviso.pdf";
import PdfSanitizzazione from "../../documents/grotta-sanitizzazione.pdf";

import "../../css/home/home.css";
//import NameForm from "./nameform";
import ResponsiveImage from "./ResponsiveImage";
import LogoFacebook from "../../images/facebook.png";
import { Link } from "react-router-dom";

import '../../i18n';
import { useTranslation } from 'react-i18next';

const iframe =
  '<iframe height="500" style="width: 100%;" scrolling="no" src="https://maps.google.com/maps?width=700&amp;height=450&amp;hl=en&amp;q=via%20del%20colli%2013%20grottaferrata+(Titolo)&amp;ie=UTF8&amp;t=k&amp;z=16&amp;iwloc=B&amp;output=embed" frameborder="no" marginheight="0" marginwidth="0"</iframe>';

function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
    />
  );
}

const CuoreImmacolato = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const { t } = useTranslation();

  return (
    <div className="home-centro-h">
      <div className="home-content">
        <div className="row">
          <div className="col-sm-8" align="center">
            <ResponsiveImage src={Grotta1} width={900} height={600} />
          </div>
          <div className="col-sm-4 home-siamodasempre">
            <hr width="150" size="1" color="#20b2aa" align="center" />

            <h1>{t('cuoreimmacolato001.label')}</h1>
            <h5>{t('cuoreimmacolato002.label')}</h5>
            <div className="row">
              <div className="col-1" />
              <div className="col-5">
                <h5>Tel. 06 9456398</h5>
              </div>
              <div className="col-2">
                <a
                  href="https://www.facebook.com/poverefiglie.dellavisitazione/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="facebook"
                    src={LogoFacebook}
                    className="logo-facebook"
                    width="30"
                    height="30"
                  />
                </a>
              </div>
              <div className="col-2">
                <Link className="link" to="/cuoreimmacolato-galleria">
                  <img
                    alt=""
                    src={GalleriaLogo}
                    width="30"
                    height="30"
                    title={t('msg026.label')}
                  />
                </Link>
              </div>
            </div>
            <h5>Email grottaferrata@istitutosuorevisitazione.it</h5>

            <h2>{t('cuoreimmacolato003.label')}</h2>

            <p></p>
            <hr width="150" size="1" color="#20b2aa" align="center" />
            <p></p>
            <div className="row">
              <div className="col" />

              <div className="col">
                <img
                  height="30"
                  width="30"
                  className="book-passionate"
                  src={PdfDownloadLogo}
                  alt="team"
                />
              </div>
              <div className="col-auto">
                <h4>{t('msg013.label')}</h4>
              </div>
              <div className="col" />
            </div>
            <div className="row" align="left">
              <div className="col-2" />
              <div className="col-auto interlinea-10">
                <ul type="square">
                  <li>
                    <a href={Pdf1}>
                      <p>{t('msg014.label')}</p>
                    </a>
                  </li>
                  <li>
                    <a href={Pdf2}>
                      <p>{t('msg015.label')}</p>
                    </a>
                  </li>
                  <li>
                    <a href={Pdf3}>
                      <p>{t('msg016.label')}</p>
                    </a>
                  </li>
                  <li>
                    <a href={Pdf4}>
                      <p>{t('msg017.label')}</p>
                    </a>
                  </li>
                  <li>
                    <a href={Pdf5}>
                      <p>{t('msg018.label')}</p>
                    </a>
                  </li>
                  <li>
                    <a href={Pdf6}>
                      <p>{t('msg019.label')}</p>
                    </a>
                  </li>
                  <li>
                    <a href={Pdf7}>
                      <p>{t('msg020.label')}</p>
                    </a>
                  </li>
                  <li>
                    <a href={PdfCovid}>
                      <p>{t('msg021.label')}</p>
                    </a>
                  </li>
                  <li>
                    <a href={PdfProtCondiv}>
                      <p>{t('msg022.label')}</p>
                    </a>
                  </li>
                  <li>
                    <a href={PdfSanitizzazione}>
                      <p>{t('msg023.label')}</p>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2" />

          <div className="col-lg-8">
            <h1 className="home-top20-c">{t('msg035.label')}</h1>

            <h5 className="home-top20-j">
            {t('cuoreimmacolato005.label')}
            </h5>

            <h1 className="home-top20-c">{t('msg003.label')}</h1>
            <h5 className="home-top20-j">
            {t('cuoreimmacolato006.label')}
            </h5>

            <Iframe iframe={iframe} />
          </div>
          <div className="col-lg-2" />
        </div>

        <div className="row">
          <div className="col-lg-2" />

          <div className="col-lg-8">
            <h1 className="home-top20-c">{t('msg028.label')}</h1>
          </div>
          <div className="col-lg-2" />
        </div>
        <div className="row home-top20-verde">
          <div className="col-sm-1" />

          <div className="col-sm-10">
            <font color="white">
              <h1>{t('msg029.label')}</h1>
              <h5>
              {t('cuoreimmacolato007.label')}
              </h5>
            </font>
            <ResponsiveImage src={Grotta2} width={1500} height={1100} />
            <p></p>
            <ResponsiveImage src={Grotta3} width={1500} height={1100} />
          </div>

          <div className="col-sm-1" />
        </div>
        <div className="row home-top20-verde">
          <div className="col-sm-1" />

          <div className="col-sm-10">
            <font color="white">
              <h1>{t('msg030.label')}</h1>
              <h5>
              {t('cuoreimmacolato008.label')}
              </h5>
            </font>
            <div className="row">
              <div className="col">
                <ResponsiveImage src={Grotta4} width={550} height={430} />
              </div>
              <div className="col">
                <ResponsiveImage src={Grotta5} width={550} height={430} />
              </div>
            </div>
            <p></p>
            <div className="row">
              <div className="col">
                <ResponsiveImage src={Grotta6} width={550} height={430} />
              </div>
              <div className="col">
                <ResponsiveImage src={Grotta7} width={550} height={430} />
              </div>
            </div>
            <p></p>
            <div className="row">
              <div className="col">
                <ResponsiveImage src={Grotta8} width={550} height={430} />
              </div>
              <div className="col">
                <ResponsiveImage src={Grotta9} width={550} height={430} />
              </div>
            </div>
            <p></p>
            <div className="row">
              <div className="col">
                <ResponsiveImage src={Grotta10} width={550} height={430} />
              </div>
              <div className="col">
                <ResponsiveImage src={Grotta13} width={550} height={430} />
              </div>
            </div>
            <p></p>

            <ResponsiveImage src={Grotta14} width={1500} height={1100} />
          </div>

          <div className="col-sm-1" />
        </div>

        <div className="row home-top20-verde">
          <div className="col-sm-1" />

          <div className="col-sm-10">
            <font color="white">
              <h1>{t('msg031.label')}</h1>
              <h5>{t('msg032.label')}</h5>
            </font>
            <div className="row">
              <div className="col">
                <ResponsiveImage src={Grotta18} width={550} height={430} />
              </div>
              <div className="col">
                <ResponsiveImage src={Grotta19} width={550} height={430} />
              </div>
            </div>
          </div>
          <div className="col-sm-1" />
        </div>
        <div className="row home-top20-verde">
          <div className="col-sm-1" />
          <div className="col-sm-10">
            <font color="white">
              <h1>{t('cuoreimmacolato009.label')}</h1>
              <h6>
                <i>
                {t('cuoreimmacolato010.label')}
                </i>
                (Leonora Speyer)
              </h6>
            </font>
            <div className="row">
              <div className="col">
                <ResponsiveImage src={Grotta24} width={500} height={500} />
              </div>
              <div className="col">
                <ResponsiveImage src={Grotta25} width={600} height={500} />
              </div>
            </div>
            <p></p>
            <div className="row">
              <div className="col">
                <ResponsiveImage src={Grotta26} width={1200} height={1300} />
              </div>
            </div>
          </div>
          <div className="col-sm-1" />
        </div>
        <div className="row home-siamodasempre">
          <div className="col-sm-3" />

          <div className="col-sm-6">
            <h1 className="home-top20-c">{t('msg004.label')}</h1>
            <h5>
            {t('msg005.label')}
            </h5>
          </div>
          <div className="col-sm-3" />
        </div>
        <div className="row home-top20-c">
          <div className="col-sm-1" />

          <div className="col-sm-5">
            <ResponsiveImage src={LogoIso} width={520} height={380} />
          </div>
          <div className="col-sm-6 home-top20-j">
            <h1>{t('msg006.label')}</h1>
            <ul>
              <li>
                <i>{t('msg007.label')}</i>
              </li>
              <li>
                <i>
                {t('msg008.label')}
                </i>
              </li>
              <li>
                <i>{t('msg009a.label')}</i>
              </li>
              <li>
                <i>
                {t('msg009b.label')}
                </i>
              </li>
              <li>
                <i>{t('msg010.label')}</i>
              </li>
              <li>
                <i>{t('msg011.label')}</i>
              </li>
              <li>
                <i>
                {t('msg012a.label')}
                </i>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CuoreImmacolato;
