import React, { useEffect } from "react";

import PdfDownloadLogo from "../../images/pdf-download-logo.png";

import Foto1 from "../../photos/brasile/CBN-01.jpg";
import Foto2 from "../../photos/brasile/CBN-02.jpg";
import Foto3 from "../../photos/brasile/CBN-adolescenti.jpg";
import Foto4 from "../../photos/brasile/CBN-catechismo.jpg";
import Foto5 from "../../photos/brasile/CBN-celebrazione-della-cena.jpg";
import Foto6 from "../../photos/brasile/CBN-momento-di-preghiera.jpg";
import Foto7 from "../../photos/brasile/CBN-panetteria.jpg";
import Foto8 from "../../photos/brasile/CBN-pasti.jpg";
import Foto9 from "../../photos/brasile/CBN-rosario.jpg";
import Foto10 from "../../photos/brasile/CBN-scuola1.jpg";
import Foto11 from "../../photos/brasile/CBN-scuola2.jpg";
import Foto12 from "../../photos/brasile/CBN-spettacoli.jpg";
import Foto13 from "../../photos/brasile/CBN-sport.jpg";
import Foto14 from "../../photos/brasile/FdS-01.jpg";
import Foto15 from "../../photos/brasile/FdS-02.jpg";
import Foto16 from "../../photos/brasile/FdS-03.jpg";
import Foto17 from "../../photos/brasile/FdS-04.jpg";
import Foto18 from "../../photos/brasile/Jacinitnho-Incontri.jpg";
import Foto19 from "../../photos/brasile/Jacinitnho-Pastorale.jpg";
import GalleriaLogo from "../../images/galleria.jpg";
/*
import Pdf1 from "../../documents/grotta-carta_servizi.pdf";
import Pdf2 from "../../documents/grotta-pai.pdf";
import Pdf3 from "../../documents/grotta-autosufficienza.pdf";
*/
import "../../css/home/home.css";
//import NameForm from "./nameform";
import ResponsiveImage from "./ResponsiveImage";
import LogoFacebook from "../../images/facebook.png";
import { Link } from "react-router-dom";

//import AuguriCBN2020 from "../../images/AuguriCBN2020.jpg";
//import PresepeCBN2020 from "../../images/PresepeCBN2020.jpg";
//import Brasile2020 from "../../images/brasile2020.jpg";

import "../../i18n";
import { useTranslation } from "react-i18next";

import "../../css/home/video-react.css";
import { Player } from "video-react";
import Video from "../../videos/brasile.mp4";

const iframe =
  '<iframe height="500" style="width: 100%;" scrolling="no" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=Maceió%20Alagoas%20+(Titolo)&amp;ie=UTF8&amp;t=k&amp;z=7&amp;iwloc=B&amp;output=embed" frameborder="no" marginheight="0" marginwidth="0"</iframe>';

function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
    />
  );
}

const Brasile = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const { t } = useTranslation();

  return (
    <div className="home-centro-h">
      <div className="home-content">
        <div className="row">
          {/*<div className="col-sm-8" align="center">  da ripristinare dopo il periodo natalizio*/}
          <div className="col-sm-8 home-spazio-20" align="center">
            {/*<ResponsiveImage src={Foto1} width={900} height={600} />  
            da ripristinare dopo il periodo natalizio
            <ResponsiveImage src={PresepeCBN2020} width={250} height={350} />*/}
            <Player playsInline poster={Foto1} src={Video} />
          </div>

          {/* questi due div vanno eliminati dopo il periodo natalizio
          <div className="col-sm-2" align="center">
            <ResponsiveImage src={AuguriCBN2020} width={250} height={350} />
          </div>
          <div className="col-sm-4" align="center">
            <ResponsiveImage src={Brasile2020} width={600} height={450} />
          </div>*/}

          <div className="col-sm-3 home-siamodasempre">
            <hr width="150" size="1" color="#20b2aa" align="center" />

            <h1>{t("brasile001.label")}</h1>
            <h5>Maceiò - Barra Nova (Alagoas)</h5>
            <h5>Garça Torta (Maceiò - Alagoas)</h5>
            <h5>{t("brasile031.label")}</h5>
            <h5>Feira de Santana (Bahía)</h5>
            <div className="row">
              <div className="col-1" />
              <div className="col-3"></div>
              <div className="col-2">
                <a
                  href="https://www.facebook.com/cbn.centrobarranova"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="facebook"
                    src={LogoFacebook}
                    className="logo-facebook"
                    width="30"
                    height="30"
                  />
                </a>
              </div>
              <div className="col-2">
                <Link className="link" to="/brasile-galleria">
                  <img
                    alt=""
                    src={GalleriaLogo}
                    width="30"
                    height="30"
                    title={t("msg026.label")}
                  />
                </Link>
              </div>
            </div>
            <h5>Email brasile@istitutosuorevisitazione.it</h5>

            <a
              href="https://www.centrobarranova.org.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              Web: www.centrobarranova.org.br
            </a>
            <h2>{t("brasile002.label")}</h2>

            <p></p>
            <hr width="150" size="1" color="#20b2aa" align="center" />
            <p></p>
            <div className="row">
              <div className="col" />

              <div className="col">
                <img
                  height="30"
                  width="30"
                  className="book-passionate"
                  src={PdfDownloadLogo}
                  alt="team"
                />
              </div>
              <div className="col-auto">
                <h4>{t("msg013.label")}</h4>
              </div>
              <div className="col" />
            </div>
            <div className="row" align="left">
              <div className="col-2" />

              <div className="col" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2" />

          <div className="col-lg-8">
            <h1 className="home-top20-c">{t("msg035.label")}</h1>

            <h5 className="home-top20-j">{t("brasile003.label")}</h5>

            <h1 className="home-top20-c">{t("msg003.label")}</h1>

            <Iframe iframe={iframe} />
          </div>
          <div className="col-lg-2" />
        </div>

        <div className="row">
          <div className="col-lg-2" />

          <div className="col-lg-8">
            <h1 className="home-top20-c">{t("msg036.label")}</h1>
          </div>
          <div className="col-lg-2" />
        </div>
        <div className="row home-top20-verde">
          <div className="col-sm-1" />

          <div className="col-sm-10">
            <font color="white">
              <h1>{t("brasile005.label")}</h1>
            </font>
            <div className="row home-top20-verde">
              <div className="col">
                <ResponsiveImage src={Foto2} width={300} height={500} />
              </div>
              <div className="col">
                <font color="white" align="justify">
                  <h5>{t("brasile006.label")}</h5>
                  <h5>{t("brasile007.label")}</h5>
                  <h5>{t("brasile008.label")}</h5>
                </font>
              </div>
            </div>
            <p></p>
            <font color="white">
              <h3>{t("brasile009.label")}</h3>
            </font>
            <ResponsiveImage src={Foto3} width={1100} height={800} />
            <p></p>
          </div>

          <div className="col-sm-1" />
        </div>
        <div className="row home-top20-verde">
          <div className="col-sm-1" />

          <div className="col-sm-10">
            <font color="white">
              <h1>{t("brasile010.label")}</h1>
            </font>
            <div className="row home-top20-verde">
              <div className="col">
                <font color="white">
                  <h3>{t("brasile011.label")}</h3>
                </font>
                <ResponsiveImage src={Foto4} width={800} height={650} />
              </div>
              <div className="col">
                <font color="white" align="justify">
                  <h5>{t("brasile012.label")}</h5>
                  <h5>{t("brasile013.label")}</h5>
                  <h5>{t("brasile014.label")}</h5>
                </font>
              </div>
            </div>
            <p></p>
            <div className="row">
              <div className="col" align="center">
                <font color="white">
                  <h3>{t("brasile015.label")}</h3>
                </font>
                <ResponsiveImage src={Foto5} width={1300} height={750} />
              </div>
            </div>
            <p></p>
            <font color="white">
              <h1>{t("brasile016.label")}</h1>
            </font>
            <div className="row">
              <div className="col">
                <font color="white">
                  <h3>{t("brasile017.label")}</h3>
                </font>
                <ResponsiveImage src={Foto6} width={550} height={360} />
              </div>
              <div className="col">
                <font color="white" align="justify">
                  <h5>{t("brasile018.label")}</h5>
                  <h5>{t("brasile019.label")}</h5>
                  <h5>{t("brasile020.label")}</h5>
                  <h5>{t("brasile021.label")}</h5>
                </font>
              </div>
            </div>
            <p></p>
            <div align="center">
              <font color="white">
                <h3>{t("brasile022.label")}</h3>
              </font>
              <ResponsiveImage src={Foto7} width={900} height={660} />
            </div>
            <p></p>
            <div className="row">
              <div className="col">
                <font color="white">
                  <h3>{t("brasile023.label")}</h3>
                </font>
                <ResponsiveImage src={Foto8} width={550} height={320} />
              </div>
              <div className="col">
                <font color="white">
                  <h3>{t("brasile024.label")}</h3>
                </font>
                <ResponsiveImage src={Foto9} width={550} height={320} />
              </div>
            </div>
            <p></p>
            <font color="white">
              <h3>{t("brasile025.label")}</h3>
            </font>
            <div className="row">
              <div className="col">
                <ResponsiveImage src={Foto10} width={550} height={430} />
              </div>
              <div className="col" align="center">
                <ResponsiveImage src={Foto11} width={350} height={430} />
              </div>
            </div>
            <p></p>
            <div className="row">
              <div className="col">
                <font color="white">
                  <h3>{t("brasile026.label")}</h3>
                </font>
                <ResponsiveImage src={Foto12} width={550} height={430} />
              </div>
              <div className="col">
                <font color="white">
                  <h3>{t("brasile027.label")}</h3>
                </font>
                <ResponsiveImage src={Foto13} width={550} height={350} />
              </div>
            </div>
          </div>

          <div className="col-sm-1" />
        </div>

        <div className="row home-top20-verde">
          <div className="col-sm-1" />

          <div className="col-sm-10">
            <font color="white">
              <h1>Feira de Santana - Bahía</h1>
            </font>
            <div className="row" align="center">
              <p></p>
              <div className="col">
                <ResponsiveImage src={Foto14} width={400} height={500} />
              </div>
              <div className="col">
                <ResponsiveImage src={Foto15} width={400} height={500} />
              </div>
            </div>
            <p></p>
            <div className="row">
              <div className="col">
                <ResponsiveImage src={Foto16} width={450} height={630} />
              </div>
              <div className="col">
                <ResponsiveImage src={Foto17} width={650} height={530} />
              </div>
            </div>
          </div>
          <div className="col-sm-1" />
        </div>
        <div className="row home-top20-verde">
          <div className="col-sm-1" />
          <div className="col-sm-10">
            <font color="white">
              <h1>{t("brasile030.label")}</h1>
            </font>
            <div className="row" align="center">
              <div className="col">
                <font color="white">
                  <h3>{t("brasile028.label")}</h3>
                </font>
                <ResponsiveImage src={Foto18} width={1000} height={750} />
              </div>
              <div className="col">
                <font color="white">
                  <h3>{t("brasile029.label")}</h3>
                </font>
                <ResponsiveImage src={Foto19} width={1000} height={750} />
              </div>
            </div>
            <p></p>
          </div>
          <div className="col-sm-1" />
        </div>
      </div>
    </div>
  );
};

export default Brasile;
