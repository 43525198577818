import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false
    },
    lng: 'ita',
    backend: {
      /* translation file path */
      loadPath: './translation/{{lng}}.json',
      allowMultiLoading: true
    },
    fallbackLng: 'ita',
    react: {
      wait: true,
      useSuspense: true

    }
  })

export default i18n
