import React from "react";

import Header from "../header/header";
import Footer from "../footer/footer";

const MainTemplate = (props) => {
  return (
    <div>
      <Header />
      {props.children}
      <Footer />
    </div>
  );
};
export default MainTemplate;
