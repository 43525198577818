// MyComponent.js
import React, { useEffect } from "react";

//import photos from "./cuoreimmacolatophotos";
import I1 from "../../photos/cuoreimmacolato/eGrotta-01.jpg";
import I2 from "../../photos/cuoreimmacolato/eGrotta-02.jpg";
import I3 from "../../photos/cuoreimmacolato/eGrotta-03.jpg";
import I4 from "../../photos/cuoreimmacolato/eGrotta-04.jpg";
import I5 from "../../photos/cuoreimmacolato/eGrotta-05.jpg";
import I6 from "../../photos/cuoreimmacolato/eGrotta-06.jpg";
import I6a from "../../photos/cuoreimmacolato/eGrotta-07.jpg";
import I6b from "../../photos/cuoreimmacolato/eGrotta-08.jpg";
import I1bis from "../../photos/cuoreimmacolato/eGrotta-01bis.jpg";
import I6c from "../../photos/cuoreimmacolato/eGrotta-09.jpg";
import I6d from "../../photos/cuoreimmacolato/eGrotta-10.jpg";
import I6e from "../../photos/cuoreimmacolato/eGrotta-11.jpg";
import I6f from "../../photos/cuoreimmacolato/eGrotta-12.jpg";
import I6g from "../../photos/cuoreimmacolato/eGrotta-13.jpg";
import I6h from "../../photos/cuoreimmacolato/eGrotta-14.jpg";
import I7 from "../../photos/cuoreimmacolato/iGrotta-01.jpg";
import I8 from "../../photos/cuoreimmacolato/iGrotta-02.jpg";
import I9 from "../../photos/cuoreimmacolato/iGrotta-03.jpg";
import I10 from "../../photos/cuoreimmacolato/iGrotta-04.jpg";
import I11 from "../../photos/cuoreimmacolato/iGrotta-05.jpg";
import I12 from "../../photos/cuoreimmacolato/iGrotta-06.jpg";
import I13 from "../../photos/cuoreimmacolato/iGrotta-07.jpg";
import I14 from "../../photos/cuoreimmacolato/iGrotta-08.jpg";
import I15 from "../../photos/cuoreimmacolato/iGrotta-09.jpg";
import I16 from "../../photos/cuoreimmacolato/iGrotta-10.jpg";
import I17 from "../../photos/cuoreimmacolato/iGrotta-11.jpg";
import I18 from "../../photos/cuoreimmacolato/iGrotta-12.jpg";
import I19 from "../../photos/cuoreimmacolato/iGrotta-13.jpg";
import I20 from "../../photos/cuoreimmacolato/iGrotta-14.jpg";
import I21 from "../../photos/cuoreimmacolato/iGrotta-15.jpg";
import I22 from "../../photos/cuoreimmacolato/iGrotta-16.jpg";
import I23 from "../../photos/cuoreimmacolato/iGrotta-17.jpg";
import I24 from "../../photos/cuoreimmacolato/iGrotta-18.jpg";
import I25 from "../../photos/cuoreimmacolato/iGrotta-19.jpg";
import I26 from "../../photos/cuoreimmacolato/iGrotta-20.jpg";
import I27 from "../../photos/cuoreimmacolato/iGrotta-21.jpg";
import I28 from "../../photos/cuoreimmacolato/iGrotta-22.jpg";
import I29 from "../../photos/cuoreimmacolato/iGrotta-23.jpg";
import I30 from "../../photos/cuoreimmacolato/iGrotta-24.jpg";
import I31 from "../../photos/cuoreimmacolato/iGrotta-25.jpg";
import I32 from "../../photos/cuoreimmacolato/iGrotta-26.jpg";
import I33 from "../../photos/cuoreimmacolato/iGrotta-27.jpg";
import I34 from "../../photos/cuoreimmacolato/iGrotta-28.jpg";
import I35 from "../../photos/cuoreimmacolato/iGrotta-29.jpg";
import I36 from "../../photos/cuoreimmacolato/iGrotta-30.jpg";
import I37 from "../../photos/cuoreimmacolato/iGrotta-31.jpg";
import I38 from "../../photos/cuoreimmacolato/iGrotta-32.jpg";
import I39 from "../../photos/cuoreimmacolato/iGrotta-33.jpg";
import I40 from "../../photos/cuoreimmacolato/iGrotta-34.jpg";
import I41 from "../../photos/cuoreimmacolato/iGrotta-35.jpg";
import I42 from "../../photos/cuoreimmacolato/iGrotta-36.jpg";

import "../../css/home/home.css";
import { SRLWrapper } from "simple-react-lightbox";
import ResponsiveImage from "./ResponsiveImage";
import GalleriaLogo from "../../images/galleria.jpg";

import '../../i18n';
import { useTranslation } from 'react-i18next';

const options = {
  overlayColor: "rgb(25, 99, 136)",
  transitionTimingFunction: "ease-in-out",
  slideTransitionSpeed: 1000,
  buttonsIconPadding: "2px",
  buttonsIconColor: "rgb(255, 255, 255)",
  enablePanzoom: false,
  showThumbnails: false,
  hideControlsAfter: 0,
};

//...snip
//{ photos.map(({id, src, title, description}) => <img key={id} src={src} title={title} alt={description} />)

const CuoreImmacolatoGalleria = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const { t } = useTranslation();

  return (
    <div className="home-centro-h">
      <div className="row">
        <div className="col-sm-4 home-siamodasempre">
          <hr width="150" size="1" color="#20b2aa" align="center" />

          <h1>{t('cuoreimmacolato001.label')}</h1>
          <h5>{t('cuoreimmacolato002.label')}</h5>
          <div className="row">
            <div className="col-1" />
            <div className="col-5">
              <h5>Tel. 06 9456398</h5>
            </div>
          </div>
          <h5>Email grottaferrata@istitutosuorevisitazione.it</h5>

          <p></p>
          <hr width="150" size="1" color="#20b2aa" align="center" />
          <p></p>
        </div>
        <div className="col-sm-3">
          <ResponsiveImage src={I1} width={650} height={450} />
        </div>
        <div className="col-sm-5">
          <img
            alt=""
            src={GalleriaLogo}
            width="100"
            height="100"
            title={t('msg026.label')}
          />
          <hr width="300" size="5" color="#20b2aa" align="center" />

          <h1>
            <font color="#00b9fd">{t('msg024.label')}</font>
          </h1>
          <h3>
            <font color="#191970">{t('msg025.label')}</font>
          </h3>
          <p></p>
          <hr width="300" size="5" color="#20b2aa" align="center" />
          <p></p>
        </div>
      </div>

      <SRLWrapper options={options}>
        <div className="d-flex flex-wrap  position-relative w-100 h-100 align-items-center align-content-center">
          <div className="cornice-foto">
            <a href={I1} data-attribute="SRL">
              <img src={I1} width="40" height="40" alt="" />
            </a>
          </div>
          <div className="cornice-foto">
            <a href={I1bis} data-attribute="SRL">
              <img src={I1bis} width="40" height="40" alt="" />
            </a>
          </div>
          <div className="cornice-foto">
            <a href={I2} data-attribute="SRL">
              <img src={I2} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I3} data-attribute="SRL">
              <img src={I3} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I4} data-attribute="SRL">
              <img src={I4} alt="" />
            </a>
          </div>
          <div className="cornice-foto">
            <a href={I5} data-attribute="SRL">
              <img src={I5} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I6} data-attribute="SRL">
              <img src={I6} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I6a} data-attribute="SRL">
              <img src={I6a} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I6b} data-attribute="SRL">
              <img src={I6b} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I6c} data-attribute="SRL">
              <img src={I6c} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I6d} data-attribute="SRL">
              <img src={I6d} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I6e} data-attribute="SRL">
              <img src={I6e} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I6f} data-attribute="SRL">
              <img src={I6f} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I6g} data-attribute="SRL">
              <img src={I6g} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I6h} data-attribute="SRL">
              <img src={I6h} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I7} data-attribute="SRL">
              <img src={I7} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I8} data-attribute="SRL">
              <img src={I8} width="40" height="40" alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I9} data-attribute="SRL">
              <img src={I9} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I10} data-attribute="SRL">
              <img src={I10} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I11} data-attribute="SRL">
              <img src={I11} alt="" />
            </a>
          </div>
          <div className="cornice-foto">
            <a href={I12} data-attribute="SRL">
              <img src={I12} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I13} data-attribute="SRL">
              <img src={I13} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I14} data-attribute="SRL">
              <img src={I14} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I15} data-attribute="SRL">
              <img src={I15} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I16} data-attribute="SRL">
              <img src={I16} width="40" height="40" alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I17} data-attribute="SRL">
              <img src={I17} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I18} data-attribute="SRL">
              <img src={I18} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I19} data-attribute="SRL">
              <img src={I19} alt="" />
            </a>
          </div>
          <div className="cornice-foto">
            <a href={I20} data-attribute="SRL">
              <img src={I20} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I21} data-attribute="SRL">
              <img src={I21} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I22} data-attribute="SRL">
              <img src={I22} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I23} data-attribute="SRL">
              <img src={I23} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I24} data-attribute="SRL">
              <img src={I24} width="40" height="40" alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I25} data-attribute="SRL">
              <img src={I25} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I26} data-attribute="SRL">
              <img src={I26} alt="" />
            </a>
          </div>

          <div className="cornice-foto">
            <a href={I27} data-attribute="SRL">
              <img src={I27} alt="" />
            </a>
          </div>
          <div className="cornice-foto">
            <a href={I28} data-attribute="SRL">
              <img src={I28} alt="" />
            </a>
          </div>
          <div className="cornice-foto">
            <a href={I29} data-attribute="SRL">
              <img src={I29} alt="" />
            </a>
          </div>
          <div className="cornice-foto">
            <a href={I30} data-attribute="SRL">
              <img src={I30} alt="" />
            </a>
          </div>
          <div className="cornice-foto">
            <a href={I31} data-attribute="SRL">
              <img src={I31} alt="" />
            </a>
          </div>
          <div className="cornice-foto">
            <a href={I32} data-attribute="SRL">
              <img src={I32} alt="" />
            </a>
          </div>
          <div className="cornice-foto">
            <a href={I33} data-attribute="SRL">
              <img src={I33} alt="" />
            </a>
          </div>
          <div className="cornice-foto">
            <a href={I34} data-attribute="SRL">
              <img src={I34} alt="" />
            </a>
          </div>
          <div className="cornice-foto">
            <a href={I35} data-attribute="SRL">
              <img src={I35} alt="" />
            </a>
          </div>
          <div className="cornice-foto">
            <a href={I36} data-attribute="SRL">
              <img src={I36} alt="" />
            </a>
          </div>
          <div className="cornice-foto">
            <a href={I37} data-attribute="SRL">
              <img src={I37} alt="" />
            </a>
          </div>
          <div className="cornice-foto">
            <a href={I38} data-attribute="SRL">
              <img src={I38} alt="" />
            </a>
          </div>
          <div className="cornice-foto">
            <a href={I39} data-attribute="SRL">
              <img src={I39} alt="" />
            </a>
          </div>
          <div className="cornice-foto">
            <a href={I40} data-attribute="SRL">
              <img src={I40} alt="" />
            </a>
          </div>
          <div className="cornice-foto">
            <a href={I41} data-attribute="SRL">
              <img src={I41} alt="" />
            </a>
          </div>
          <div className="cornice-foto">
            <a href={I42} data-attribute="SRL">
              <img src={I42} alt="" />
            </a>
          </div>
        </div>
      </SRLWrapper>
    </div>
  );
};

export default CuoreImmacolatoGalleria;
